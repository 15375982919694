import React from "react";

import cn from "../../../lib/cn";
import { carbon, streamLine } from "../../../resources/js/icons";
import H2 from "../../../shared/typography/H2";
import P from "../../../shared/typography/P";
import Line from "../../../shared/ui/Line";
import Tag from "../../../shared/ui/tag/Tag";
import FundStatus from "./FundStatus";

const ProfileCard = ({ projectName, type, listing, solarAssetOwned }) => {
    return (
        <div className="bg-black-600 mb-3 p-2.4 rounded-[3rem] gap-2  flex justify-between items- ">
            {/* @DESC:: left @habib610 Tue January 07,2025 */}
            <div className=" w-7/12 2xl:w-7/12">
                <H2 className="lg:text-2xl xl:text-xxl 2xl:text-3xl font-vietnam text-white mb-1">
                    {projectName}
                </H2>

                <div className="flex justify-between gap-2 flex-wrap">
                    <Tag
                        text={type.replace("_", " ")}
                        className="text-gray-500 px-0 bg-black-400 font-vietnam rounded-full font-light"
                        textClass="xl:text-sm 2xl:text-sm"
                    />
                    <Tag
                        text="14 Investors"
                        className="text-green-400 bg-green-50 font-vietnam rounded-full font-light "
                        textClass="xl:text-sm 2xl:text-sm"
                    />
                </div>
                <Line className="w-full h-0.1 bg-gray-secondary" />
                <FundStatus
                    activationDate={listing?.activationDate}
                    fundLimit={listing?.fundLimit}
                    fundRaised={listing?.fundRaised}
                    listingStatus={listing?.listingStatus}
                />
            </div>

            {/* @DESC:: line @habib610 Tue January 07,2025 */}
            {/* <div className="min-h-full w-0.1 bg-red"></div> */}
            <div className="min-h-full  w-0.1  bg-gray-secondary" />

            {/* @DESC:: Right @habib610 Tue January 07,2025 */}
            <div className=" w-5/12 2xl:w-4/12 self-center">
                <CarbonStatus
                    className="mb-1.5"
                    name="Equivalent to planting"
                    value={`${Math.round(67.23 * solarAssetOwned)} Trees`}
                    src={streamLine}
                />
                <CarbonStatus
                    src={carbon}
                    className=""
                    name="Co2 Offset Every Year"
                    value={`${Number(1.39 * solarAssetOwned).toFixed(
                        2
                    )} Tonnes`}
                />
            </div>
        </div>
    );
};

export default ProfileCard;

const CarbonStatus = ({ src, name, value, className }) => {
    return (
        <div
            className={cn(
                "flex items-center bg-black-400 gap-x-1.8 gap-y-1 px-1 xl:px-2 py-1 rounded-sm",
                className
            )}
        >
            <img src={src} alt="carbon_icon" className="h-4 " />
            <div>
                <P className="font-vietnam font-light text-white mb-1 xl:text-sm">
                    {name}
                </P>
                <P className="font-vietnam font-medium text-white">{value}</P>
            </div>
        </div>
    );
};
