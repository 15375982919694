import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { getCurrencyAmount } from "../../../../../helpers/helpers";
import generateRandomColors from "../../../../../hooks/useRandomColorGenerator";
import P from "../../../../../shared/typography/P";
import Dropdown from "../../../../../shared/ui/Dropdown/Dropdown";

const PayoutGraph = ({ data, error, loading }) => {
    const currentDate = new Date();
    const [selectedFilter, setSelectedFilter] = useState("1Y");
    const [investments, setInvestments] = useState([]);

    useEffect(() => {
        if (data) {
            setInvestments(data?.data?.portfolio?.investments || []);
        }
    }, [data]);

    const filters = {
        "6M": (() => {
            const originalStartDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - 6,
                1
            );
            const originalEndDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 2,
                0
            );

            let monthsWithoutData = 0;
            let currentCheckDate = new Date(originalStartDate);

            while (currentCheckDate <= currentDate) {
                const hasDataInMonth = investments.some((investment) => {
                    const investmentStartDate = new Date(
                        investment.investmentDate *
                            (investment.investmentDate < 1e12 ? 1000 : 1)
                    );

                    const investmentEndDate = new Date(investmentStartDate);
                    investmentEndDate.setMonth(
                        investmentEndDate.getMonth() +
                            investment.paidInstallments +
                            investment.pendingInstallments
                    );

                    return (
                        currentCheckDate >= investmentStartDate &&
                        currentCheckDate <= investmentEndDate
                    );
                });

                if (!hasDataInMonth) {
                    monthsWithoutData++;
                } else {
                    break;
                }

                currentCheckDate.setMonth(currentCheckDate.getMonth() + 1);
            }

            if (monthsWithoutData >= 3) {
                return {
                    startDate: new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth() - 1,
                        1
                    ),
                    endDate: new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth() + 5,
                        0
                    ),
                    frequency: "Monthly",
                };
            }

            return {
                startDate: originalStartDate,
                endDate: originalEndDate,
                frequency: "Monthly",
            };
        })(),
        "1Y": (() => {
            const originalStartDate = new Date(
                currentDate.getFullYear() - 1,
                currentDate.getMonth(),
                1
            );
            const originalEndDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 4,
                0
            );

            let shiftNeeded = false;

            for (let i = 0; i < 2; i++) {
                const pairStartDate = new Date(
                    currentDate.getFullYear() - 1,
                    currentDate.getMonth() + i * 2,
                    1
                );
                const pairEndDate = new Date(
                    currentDate.getFullYear() - 1,
                    currentDate.getMonth() + (i + 1) * 2,
                    0
                );

                const hasDataInPair = investments.some((investment) => {
                    const investmentStartDate = new Date(
                        investment.investmentDate *
                            (investment.investmentDate < 1e12 ? 1000 : 1)
                    );

                    const investmentEndDate = new Date(investmentStartDate);
                    investmentEndDate.setMonth(
                        investmentEndDate.getMonth() +
                            investment.paidInstallments +
                            investment.pendingInstallments
                    );

                    // Check if the investment falls within the bimonthly pair
                    return (
                        investmentStartDate <= pairEndDate &&
                        investmentEndDate >= pairStartDate
                    );
                });

                if (!hasDataInPair) {
                    shiftNeeded = true;
                    break;
                }
            }

            if (shiftNeeded) {
                return {
                    startDate: new Date(
                        currentDate.getFullYear() - 1,
                        currentDate.getMonth() + 8,
                        1
                    ),
                    endDate: new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth() + 10,
                        0
                    ),
                    frequency: "BiMonthly",
                };
            }

            return {
                startDate: originalStartDate,
                endDate: originalEndDate,
                frequency: "BiMonthly",
            };
        })(),

        "2Y": (() => {
            const originalStartDate = new Date(
                currentDate.getFullYear() - 2,
                currentDate.getMonth(),
                1
            );
            const originalEndDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 4,
                0
            );

            let shiftNeeded = false;

            for (let i = 0; i < 4; i++) {
                const quarterStartDate = new Date(
                    currentDate.getFullYear() - 2,
                    currentDate.getMonth() + i * 3,
                    1
                );
                const quarterEndDate = new Date(
                    currentDate.getFullYear() - 2,
                    currentDate.getMonth() + (i + 1) * 3,
                    0
                );

                const hasDataInQuarter = investments.some((investment) => {
                    const investmentStartDate = new Date(
                        investment.investmentDate *
                            (investment.investmentDate < 1e12 ? 1000 : 1)
                    );

                    const investmentEndDate = new Date(investmentStartDate);
                    investmentEndDate.setMonth(
                        investmentEndDate.getMonth() +
                            investment.paidInstallments +
                            investment.pendingInstallments
                    );

                    return (
                        investmentStartDate <= quarterEndDate &&
                        investmentEndDate >= quarterStartDate
                    );
                });

                if (!hasDataInQuarter) {
                    shiftNeeded = true;
                    break;
                }
            }

            if (shiftNeeded) {
                return {
                    startDate: new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        1
                    ),
                    endDate: new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth() + 16,
                        0
                    ),
                    frequency: "Quarterly",
                };
            }

            return {
                startDate: originalStartDate,
                endDate: originalEndDate,
                frequency: "Quarterly",
            };
        })(),

        "5Y": (() => {
            const midYear = currentDate.getFullYear();
            const originalStartDate = new Date(
                midYear - 5,
                currentDate.getMonth(),
                1
            );
            const originalEndDate = new Date(
                midYear,
                currentDate.getMonth(),
                0
            );

            const hasDataInFirstTwoYears = investments.some((investment) => {
                const investmentStartDate = new Date(investment.investmentDate);
                return (
                    investmentStartDate >= originalStartDate &&
                    investmentStartDate <=
                        new Date(midYear - 3, currentDate.getMonth(), 0)
                );
            });

            if (!hasDataInFirstTwoYears) {
                return {
                    startDate: new Date(midYear - 1, currentDate.getMonth(), 1),
                    endDate: new Date(midYear + 4, currentDate.getMonth(), 0),
                    frequency: "Yearly",
                };
            }

            return {
                startDate: originalStartDate,
                endDate: originalEndDate,
                frequency: "Yearly",
            };
        })(),
        "All(10Y)": (() => {
            const midYear = currentDate.getFullYear();
            const originalStartDate = new Date(
                midYear - 10,
                currentDate.getMonth(),
                1
            );
            const originalEndDate = new Date(
                midYear,
                currentDate.getMonth(),
                0
            );

            const hasDataInFirstFiveYears = investments.some((investment) => {
                const investmentStartDate = new Date(investment.investmentDate);
                return (
                    investmentStartDate >= originalStartDate &&
                    investmentStartDate <=
                        new Date(midYear - 5, currentDate.getMonth(), 0)
                );
            });

            if (!hasDataInFirstFiveYears) {
                return {
                    startDate: new Date(midYear - 2, currentDate.getMonth(), 1),
                    endDate: new Date(midYear + 7, currentDate.getMonth(), 0),
                    frequency: "Yearly",
                };
            }

            return {
                startDate: originalStartDate,
                endDate: originalEndDate,
                frequency: "Yearly",
            };
        })(),
    };

    const generateStackedData = (startDate, endDate, frequency) => {
        if (!investments.length) return { categories: [], series: [] };

        const categories = [];
        const currentDataByInvestment = {};
        const futureDataByInvestment = {};

        investments.forEach((investment) => {
            currentDataByInvestment[investment.investmentId] = [];
            futureDataByInvestment[investment.investmentId] = [];
        });

        const current = new Date(startDate);
        const step =
            frequency === "Monthly"
                ? 1
                : frequency === "BiMonthly"
                ? 2
                : frequency === "Quarterly"
                ? 3
                : 12;

        while (current <= endDate) {
            const monthLabel = current.toLocaleDateString("en-US", {
                month: "short",
                year: "2-digit",
            });
            categories.push(monthLabel);

            investments.forEach((investment) => {
                const filteredUpcomingPayouts =
                    investment.upcomingPayouts?.filter(
                        (payout) =>
                            payout.investment_id ===
                            Number(investment.investmentId)
                    ) || [];
                let firstPayoutDate = new Date(
                    investment.investmentDate *
                        (investment.investmentDate < 1e12 ? 1000 : 1)
                );

                let investmentStartDate = new Date(
                    investment.investmentDate *
                        (investment.investmentDate < 1e12 ? 1000 : 1)
                );
                if (filteredUpcomingPayouts.length > 0) {
                    filteredUpcomingPayouts.sort(
                        (a, b) => a.payout_number - b.payout_number
                    );
                    investmentStartDate = new Date(
                        filteredUpcomingPayouts[0].payout_date *
                            (filteredUpcomingPayouts[0].payout_date < 1e12
                                ? 1000
                                : 1)
                    );
                    firstPayoutDate = new Date(
                        filteredUpcomingPayouts[0].payout_date *
                            (filteredUpcomingPayouts[0].payout_date < 1e12
                                ? 1000
                                : 1)
                    );
                } else {
                    investmentStartDate.setMonth(
                        investmentStartDate.getMonth() + 3
                    );
                }

                const totalTenureMonths =
                    investment.paidInstallments +
                    investment.pendingInstallments;

                const tenureEndDate = new Date(investmentStartDate);
                tenureEndDate.setMonth(
                    tenureEndDate.getMonth() + totalTenureMonths
                );

                let aggregatedPayout = 0;
                const isFutureMonth = current > currentDate;

                if (filteredUpcomingPayouts.length) {
                    const adjustedStep =
                        ((frequency === "Monthly" ||
                            frequency === "BiMonthly" ||
                            frequency === "Quarterly") &&
                            firstPayoutDate.getMonth() !== 1) ||
                        current.getFullYear() > firstPayoutDate.getFullYear()
                            ? 0
                            : firstPayoutDate.getMonth();
                    if (frequency === "Quarterly") {
                        for (let i = -2; i <= 0; i++) {
                            // Include the quarter and 3 months before it
                            const checkDate = new Date(current);
                            checkDate.setMonth(checkDate.getMonth() + i);

                            if (checkDate > tenureEndDate) break;

                            filteredUpcomingPayouts.forEach((payout) => {
                                const payoutDate = new Date(
                                    payout.payout_date *
                                        (payout.payout_date < 1e12 ? 1000 : 1)
                                );

                                if (
                                    payoutDate.getFullYear() ===
                                        checkDate.getFullYear() &&
                                    payoutDate.getMonth() ===
                                        checkDate.getMonth()
                                ) {
                                    aggregatedPayout += Number(
                                        investment.monthlyPayout
                                    );
                                }
                            });

                            // Add payouts if they are part of regular installments
                            if (
                                filteredUpcomingPayouts.length === 0 &&
                                checkDate <= tenureEndDate
                            ) {
                                aggregatedPayout += Number(
                                    investment.monthlyPayout
                                );
                            }
                        }
                    } else {
                        for (let i = 0; i < step - adjustedStep; i++) {
                            const checkDate = new Date(current);
                            checkDate.setMonth(checkDate.getMonth() + i);

                            filteredUpcomingPayouts.forEach((payout) => {
                                const payoutDate = new Date(
                                    payout.payout_date *
                                        (payout.payout_date < 1e12 ? 1000 : 1)
                                );

                                if (
                                    (frequency !== "BiMonthly" ||
                                        checkDate >= investmentStartDate) &&
                                    payoutDate.getFullYear() ===
                                        checkDate.getFullYear() &&
                                    payoutDate.getMonth() ===
                                        checkDate.getMonth()
                                ) {
                                    aggregatedPayout += Number(
                                        investment.monthlyPayout
                                    );
                                }
                            });
                        }
                    }
                } else if (current <= tenureEndDate) {
                    for (let i = 0; i < step; i++) {
                        const checkDate = new Date(current);
                        checkDate.setMonth(checkDate.getMonth() + i);

                        if (checkDate > tenureEndDate) break;

                        aggregatedPayout += Number(investment.monthlyPayout);
                    }
                }

                const investmentDayAdjustedStart = new Date(
                    current.getFullYear(),
                    current.getMonth(),
                    investmentStartDate.getDate()
                );
                investmentDayAdjustedStart.setHours(0, 0, 0, 0);
                investmentStartDate.setHours(0, 0, 0, 0);

                if (
                    investmentDayAdjustedStart < investmentStartDate ||
                    current > tenureEndDate
                ) {
                    currentDataByInvestment[investment.investmentId].push(0);
                    futureDataByInvestment[investment.investmentId].push(0);
                } else {
                    if (isFutureMonth) {
                        futureDataByInvestment[investment.investmentId].push(
                            aggregatedPayout
                        );
                        currentDataByInvestment[investment.investmentId].push(
                            0
                        );
                    } else {
                        currentDataByInvestment[investment.investmentId].push(
                            aggregatedPayout
                        );
                        futureDataByInvestment[investment.investmentId].push(0);
                    }
                }
            });

            switch (frequency) {
                case "Monthly":
                    current.setMonth(current.getMonth() + 1);
                    break;
                case "BiMonthly":
                    current.setMonth(current.getMonth() + 2);
                    break;
                case "Quarterly":
                    current.setMonth(current.getMonth() + 3);
                    break;
                case "Yearly":
                    current.setFullYear(current.getFullYear() + 1);
                    break;
                default:
                    current.setMonth(current.getMonth() + 1);
            }
        }

        const series = [
            ...Object.entries(currentDataByInvestment).map(
                ([investmentId, data]) => {
                    const investment = investments.find(
                        (inv) => inv.investmentId === Number(investmentId)
                    );

                    const investmentName = investment?.name
                        ? `${investment?.name} - ${investmentId}`
                        : `${investmentId}`;

                    return {
                        name: `${investmentName}`,
                        group: "current",
                        data,
                    };
                }
            ),
            ...Object.entries(futureDataByInvestment).map(
                ([investmentId, data]) => {
                    const investment = investments.find(
                        (inv) => inv.investmentId === Number(investmentId)
                    );
                    const investmentName = investment?.name
                        ? `${investment?.name}-${investmentId}`
                        : `${investmentId}`;

                    return {
                        name: `${investmentName}`,
                        group: "future",
                        data,
                    };
                }
            ),
        ];

        return { categories, series };
    };

    const { startDate, endDate, frequency } = filters[selectedFilter];
    const { categories, series } = generateStackedData(
        startDate,
        endDate,
        frequency
    );
    const colors = generateRandomColors(Math.ceil(series?.length / 2));

    const colorAssignments = series.map((s, index) => {
        let colorIndex = index;
        if (index >= colors.length) {
            colorIndex = index - colors.length;
        }
        const baseColor = colors[colorIndex];

        return s.group === "future" ? `${baseColor}80` : baseColor;
    });

    const chartOptions = {
        series: series,
        options: {
            chart: {
                type: "bar",
                height: 350,
                stacked: true,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: selectedFilter === "5Y" ? 8 : 10,
                    columnWidth: selectedFilter === "All(10Y)" ? "55%" : "25%",
                    borderRadiusApplication: "around",
                    borderRadiusWhenStacked: "all",
                },
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        xaxis: {
                            labels: {
                                style: {
                                    colors: "#132232",
                                    fontSize: "12px",
                                },
                            },
                        },
                        yaxis: {
                            labels: {
                                style: {
                                    colors: "#132232",
                                    fontSize: "12px",
                                },
                            },
                        },
                    },
                },
                {
                    breakpoint: 300,
                    chart: {
                        height: 300,
                    },

                    options: {
                        grid: {
                            borderColor: "#040506",
                            row: {
                                opacity: 0.1,
                            },
                        },

                        plotOptions: {
                            bar: {
                                horizontal: false,
                                borderRadius: 5,
                                columnWidth: "50%",
                                borderRadiusApplication: "around",
                                borderRadiusWhenStacked: "all",
                            },
                        },

                        xaxis: {
                            labels: {
                                style: {
                                    colors: "#fff",
                                    fontSize: "10px",
                                },
                            },
                        },
                        yaxis: {
                            labels: {
                                style: {
                                    colors: "#fff",
                                    fontSize: "10px",
                                },
                                formatter: function (val) {
                                    return getCurrencyAmount(val);
                                },
                            },
                        },
                    },
                },
                {
                    breakpoint: 980,
                    chart: {
                        height: 300,
                    },

                    options: {
                        grid: {
                            borderColor: "#040506",
                            row: {
                                opacity: 0.1,
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,

                                borderRadius: 8,
                                columnWidth: "30%",
                                borderRadiusApplication: "around",
                                borderRadiusWhenStacked: "all",
                            },
                        },

                        xaxis: {
                            labels: {
                                style: {
                                    colors: "#fff",
                                    fontSize: "10px",
                                },
                            },
                        },
                        yaxis: {
                            align: "right",
                            labels: {
                                style: {
                                    colors: "#fff",
                                    fontSize: "10px",
                                },
                                formatter: function (val) {
                                    return getCurrencyAmount(val);
                                },
                            },
                        },
                    },
                },
            ],
            dataLabels: {
                enabled: false,
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return getCurrencyAmount(val);
                    },
                },
            },
            xaxis: {
                tickPlacement: "on",
                tickAmount: "dataPoints",

                categories,
            },
            legend: {
                show: false,
            },
            fill: {
                colors: colorAssignments,
            },
        },
    };

    const filterOptions = ["6M", "1Y", "2Y", "5Y", "All(10Y)"];

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data</p>;

    return (
        <div>
            {series && series.length > 0 && (
                <>
                    <div className="flex justify-between items-center">
                        <P className="text-white lg:text-black-600">
                            Monthly Payout
                        </P>
                        <Dropdown
                            selected={selectedFilter}
                            onSelect={setSelectedFilter}
                            data={filterOptions}
                            className="filter-dropdown"
                            selectedClass={" text-white lg:text-black-600"}
                            icon={<MdOutlineKeyboardArrowDown />}
                        />
                    </div>

                    <ReactApexChart
                        options={chartOptions?.options}
                        series={chartOptions?.series || []}
                        type="bar"
                        height={350}
                    />
                </>
            )}
        </div>
    );
};

export default PayoutGraph;
