import React, { useCallback } from "react";
import { IoTimeOutline } from "react-icons/io5";

import moment from "moment";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../../../constants/common";
import {
    GENERATE_DOC_ENDPOINT,
    PORTFOLIO_INVESTMENT_ID,
    PROFILE_API_ENDPOINT,
    VERIFY_GENERATE_DOC,
} from "../../../../constants/routes";
import { useContextState } from "../../../../context/ContextProvider";
import cn from "../../../../lib/cn";
import { mapMarker, streamLine } from "../../../../resources/js/icons";
import { signDocument } from "../../../../services/digio";
import request from "../../../../services/request";
import H2 from "../../../../shared/typography/H2";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";
import Line from "../../../../shared/ui/Line";
import Tag from "../../../../shared/ui/tag/Tag";
import Distribution from "../Distribution";
import StreamlineTree from "../StreamlineTree";

const DetailsHead = ({
    name,
    status,
    date,
    totalPayout,
    investmentAmount,
    digioStatus,
    investmentId,
    currentTab,
    investment,
    plant,
}) => {
    let distribution = Math.round(
        (investment?.paid_installments * 100) / investment?.pending_installments
    );
    const verifyGenerateDocumentHandler = useCallback(async () => {
        try {
            const verifyResponse = await request.authPost({
                endpoint: VERIFY_GENERATE_DOC,
                body: {
                    investmentId: investmentId,
                },
                errorData: true,
            });
            if (
                verifyResponse.status === "SUCCESS" &&
                verifyResponse.data.investment
            ) {
                // setPortfolioData(verifyResponse);
            }
        } catch (error) {}
    }, [investmentId]);
    const signGeneratedDocumentHandler = useCallback(async () => {
        try {
            const response = await request.authGet({
                endpoint: PROFILE_API_ENDPOINT,
                errorData: true,
            });

            let res = await request.authGet({
                endpoint: `${PORTFOLIO_INVESTMENT_ID}/${investmentId}`,
            });

            let identifier;
            if (
                res?.data?.investment &&
                res?.data?.investment?.investorIdentifierType === "email"
            ) {
                identifier = response?.data?.investor_profile.email;
            } else {
                let phoneNumber = response?.data?.investor_profile?.phoneNumber;
                if (phoneNumber.startsWith("91")) {
                    phoneNumber = phoneNumber.substring(2);
                }
                identifier = phoneNumber.slice(-10);
            }

            await signDocument(
                res?.data?.investment?.digioDocId,
                identifier,
                verifyGenerateDocumentHandler
            );

            return;
        } catch (error) {
            if (error?.data?.code === "EXCEEDED") {
                return;
            }
            toast.error(
                error?.message && typeof error?.message === "string"
                    ? error?.message
                    : ERROR_MSG
            );
            return;
        }
    }, [investmentId, verifyGenerateDocumentHandler]);

    const singDocumentHandler = useCallback(async () => {
        try {
            const response = await request.authPost({
                endpoint: GENERATE_DOC_ENDPOINT,
                body: {
                    investmentId: investmentId,
                    signType: "electronic",
                },
                errorData: true,
            });

            const identifier = response?.data?.investor_identifier;
            await signDocument(
                response?.data?.document_id,
                identifier,
                verifyGenerateDocumentHandler
            );

            return;
        } catch (error) {
            toast.error(
                error?.message && typeof error?.message === "string"
                    ? error?.message
                    : ERROR_MSG
            );
            return;
        }
    }, [investmentId, verifyGenerateDocumentHandler]);

    const { state } = useContextState();

    let kycSummary = state?.user?.kycSummary;
    let isEnabledKyc =
        kycSummary?.panVerificationStatus === "VERIFIED" &&
        kycSummary?.aadharVerificationStatus === "VERIFIED" &&
        kycSummary?.bankVerificationStatus === "VERIFIED";
    return (
        <div className="lg:bg-black-600 lg:px-2  lg:py-2 lg:rounded-3xl  ">
            <div className="flex justify-between  gap-3 lg:flex-wrap xl:flex-nowrap">
                <div className="w-full  xl:w-7/12">
                    <H2 className="mb-0.8 font-vietnam text-white  text-wrap xl:text-3xl 2xl:text-3xl">
                        {name}
                    </H2>
                    <div className="flex  items-end justify-between lg:pb-1 mb-2 xl:mb-0">
                        <div>
                            <P className="text-gray-500 lg:text-sm  xl:text-sm font-vietnam">
                                {date
                                    ? moment(date * 1000).format("DD/MM/yyyy")
                                    : ""}
                            </P>
                        </div>
                        <div className="flex xl:hidden items-center  ">
                            <Tag
                                text={status}
                                className="mr-1.6 rounded-full "
                                textClass="text-green-400 font-vietnam"
                            />
                            <IoTimeOutline className="text-white text-xxl" />
                        </div>
                    </div>
                    <Line
                        className={
                            "hidden lg:flex lg:bg-gray-secondary lg:my-0.1"
                        }
                    />
                    <div className="bg-blue-600 lg:bg-black-600 p-1.6 lg:p-0.5 rounded-2">
                        {currentTab === 0 ||
                        document.body.clientWidth > 1000 ? (
                            <div className=" bg-black-600  lg:w-full lg:bg-black-600 p-1.6 lg:p-0 lg:pt-1.5 lg:flex lg:justify-between rounded-2">
                                <div className="flex lg:w-full items-center gap-1.6 ">
                                    <div className="h-4">
                                        <img
                                            src={streamLine}
                                            alt="stream_line"
                                        />
                                    </div>
                                    <div className="flex justify-between lg:w-full items-center gap-0.2 xl:gap-[10rem] flex-1 ">
                                        <div>
                                            <P className=" font-vietnam lg:text-nowrap lg:text-sm  xl:text-xs text-gray-550 text-xs">
                                                Total Payouts
                                            </P>
                                            <P className=" font-vietnam text-white text-sm lg:text-sm  xl:text-sm font-medium">
                                                ₹ {totalPayout.toFixed(2)}
                                            </P>
                                        </div>
                                        <div>
                                            <P className=" font-vietnam text-xs lg:text-nowrap lg:text-sm  xl:text-xs text-gray-550">
                                                My Investment
                                            </P>
                                            <P className=" font-vietnam text-white text-sm  lg:text-sm  xl:text-sm font-medium">
                                                ₹ {investmentAmount}
                                            </P>
                                        </div>
                                    </div>
                                </div>
                                {/* @DESC:: Mobile @habib610 Mon January 06,2025 */}
                                <Line className="bg-gray-secondary" />
                                <div className="flex justify-between lg:hidden">
                                    <div>
                                        <P className="text-gray-550  mb-0.2">
                                            Investment Agreement
                                        </P>
                                        {digioStatus && (
                                            <P
                                                className={
                                                    digioStatus === "SIGNED"
                                                        ? "text-green-300"
                                                        : "text-orange"
                                                }
                                            >
                                                {digioStatus}
                                            </P>
                                        )}
                                    </div>
                                    <div className="flex items-center flex-wrap">
                                        <button
                                            onClick={
                                                digioStatus === "GENERATED"
                                                    ? signGeneratedDocumentHandler
                                                    : singDocumentHandler
                                            }
                                            disabled={!isEnabledKyc}
                                            className="bg-primary text-white py-0.4 px-0.8 rounded disabled:opacity-40 disabled:hover:cursor-not-allowed"
                                        >
                                            Click to Sign
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <StreamlineTree />
                                <div className="flex justify-between  w-full mt-2.4">
                                    <div>
                                        <P className=" text-white font-vietnam">
                                            {plant?.city}
                                        </P>
                                        <P className=" font-vietnam  text-wrap flex items-center text-sm text-gray-550  gap-0.2 ">
                                            <img
                                                src={mapMarker}
                                                alt="location"
                                            />
                                            {plant?.state}
                                        </P>
                                    </div>
                                    <div>
                                        <H4 className="text-white font-medium">
                                            {plant?.capacity &&
                                                plant.capacity / 1000}
                                            KWp
                                        </H4>
                                        <P className="text-base text-white">
                                            Plant Size
                                        </P>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="hidden xl:inline-flex min-h-full w-0.1 bg-gray-secondary my-2"></div>
                <div className="hidden  lg:block lg:w-full  xl:w-5/12 ">
                    <div className="hidden xl:flex  items-center justify-end  mb-1.5">
                        <Tag
                            text={status}
                            className="mr-1.6 rounded-full "
                            textClass="text-green-400 lg:text-sm  xl:text-sm font-vietnam"
                        />{" "}
                        <IoTimeOutline className="text-white text-xxl" />
                    </div>
                    <div className="flex justify-between items-center gap-3 bg-black-400  p-1 rounded-sm">
                        <div>
                            <P className="text-gray-550 mb-0.2  lg:text-nowrap md:text-sm xl:text-sm">
                                Investment Agreement
                            </P>
                            {digioStatus && (
                                <P
                                    className={cn(
                                        digioStatus === "SIGNED"
                                            ? "text-green-300"
                                            : "text-orange",
                                        "md:text-sm xl:text-base"
                                    )}
                                >
                                    {digioStatus}
                                </P>
                            )}
                        </div>
                        <div className="flex flex-col">
                            <button
                                disabled={!isEnabledKyc}
                                onClick={
                                    digioStatus === "GENERATED"
                                        ? signGeneratedDocumentHandler
                                        : singDocumentHandler
                                }
                                className="bg-primary  lg:text-nowrap md:text-xs xl:text-sm text-white py-0.4 px-0.8 rounded-2xl disabled:opacity-40 disabled:hover:cursor-not-allowed"
                            >
                                Click to Sign
                            </button>
                        </div>
                    </div>

                    <div className="flex justify-between items-center mb-1.6 font-vietnam mt-1.6">
                        <P className="font-vietnam font-thin text-white md:text-sm xl:text-sm">
                            Payout Schedule
                        </P>
                        <P className="text-white font-vietnam md:text-sm xl:text-sm">
                            {investment?.paid_installments}/
                            {investment?.pending_installments}
                        </P>
                    </div>
                    <Distribution percentage={distribution} className="" />
                </div>
            </div>
        </div>
    );
};

export default DetailsHead;
