import React from "react";

import cn from "../../../lib/cn";
import { streamLine } from "../../../resources/js/icons";
import P from "../../../shared/typography/P";

const StreamlineTree = ({
    className = "",
    headingText = "",
    subText = "",
    solarAssetOwned = 0,
}) => {
    return (
        <div className={cn("bg-black-600 p-1.6 rounded-2", className)}>
            <P className="w-full  py-0.4 text-center text-white bg-gray-400/20 rounded font-vietnam mb-1.6 xl:text-base ">
                You owned{" "}
                <span className="font-medium">
                    {" "}
                    {solarAssetOwned.toFixed(2)}KWp
                </span>{" "}
                solar assets
            </P>
            <div className="flex  items-center gap-1.6  ">
                <div className="h-4">
                    <img src={streamLine} alt="stream_line" />
                </div>
                <div className="flex justify-between items-center gap-0.2 flex-1 ">
                    <div>
                        <P
                            className={cn(
                                "font-vietnam text-gray-550 text-xs",
                                subText
                            )}
                        >
                            Co2 Offset
                        </P>
                        <P
                            className={cn(
                                " font-vietnam text-white text-sm font-medium",
                                headingText
                            )}
                        >
                            {`${Number(1.39 * solarAssetOwned).toFixed(
                                2
                            )} Tonnes`}
                        </P>
                    </div>
                    <div>
                        <P
                            className={cn(
                                " font-vietnam text-xs text-gray-550",
                                subText
                            )}
                        >
                            Equivalent to planting
                        </P>
                        <P
                            className={cn(
                                " font-vietnam text-white text-sm font-medium",
                                headingText
                            )}
                        >
                            {`${Math.round(67.23 * solarAssetOwned)} Trees`}
                        </P>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StreamlineTree;
