import React from "react";
import { IoChevronDown } from "react-icons/io5";
import { investFaq } from "../../../../constants/faqData";
import P from "../../../../shared/typography/P";
import Accordion from "../../../../shared/ui/Accordian/Accordian";

const FaqItem = ({ question, answer, list, format }) => {
    return (
        <Accordion
            labelClass="font-regular font-nunito text-gray  text-sm sm:text-base xl:text-base 2xl:text-xl"
            key={question}
            label={question}
            contentMargin="mt-1.6"
            size="s"
            closedIcon={IoChevronDown}
            openClass="text-primary"
            wrapperClass="p-0.8 mb-1.6 lg:mb-1.2"
        >
            {answer ? (
                <P
                    className={`${
                        list ? "mb-0.5 " : "mb-0"
                    } text-gray-550 font-normal text-justify text-sm sm:text-base xl:text-base 2xl:text-lg`}
                >
                    {answer}
                </P>
            ) : null}
            {format ? (
                <P
                    dangerouslySetInnerHTML={format}
                    className=" text-gray-550   text-sm sm:text-base xl:text-sm 2xl:text-lg"
                ></P>
            ) : null}
            {list
                ? list.map((item, index) => (
                      <div
                          className="flex mb-0.5"
                          key={index}
                          style={{ marginBottom: "5px" }}
                      >
                          <div>
                              <P className="w-[15px] mr-1 text-gray-550   text-sm sm:text-base xl:text-base 2xl:text-lg">
                                  {`${index + 1}.`}
                              </P>
                          </div>
                          <div className="flex-1">
                              <P className="text-gray-550 text-justify   text-sm sm:text-base xl:text-base 2xl:text-lg">
                                  {item}
                              </P>
                          </div>
                      </div>
                  ))
                : null}
        </Accordion>
    );
};
const renderFaq = (data, value) => {
    return data.map(
        (item, i) =>
            i % 2 === value && (
                <FaqItem
                    key={item?.question}
                    list={item?.list}
                    question={item?.question}
                    answer={item?.answer}
                    format={item?.format}
                />
            )
    );
};
const Faq = () => {
    return (
        <>
            <div className=" w-full lg:hidden">
                {investFaq.map((item, i) => (
                    <FaqItem
                        key={item?.question}
                        list={item?.list}
                        question={item?.question}
                        answer={item?.answer}
                        format={item?.format}
                    />
                ))}
            </div>

            <div className="lg:flex justify-between gap-3.2">
                <div className="flex-1">{renderFaq(investFaq, 0)}</div>
                <div className="flex-1">{renderFaq(investFaq, 1)}</div>
            </div>
        </>
    );
};

export default Faq;
