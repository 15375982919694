import React, { useState } from "react";
import { funding, mapMarker } from "../../../../resources/js/icons";
import P from "../../../../shared/typography/P";

import moment from "moment";
import { getCurrencyAmount } from "../../../../helpers/helpers";
import cn from "../../../../lib/cn";
import H4 from "../../../../shared/typography/H4";
import Line from "../../../../shared/ui/Line";
import BorderedTab from "../../../../shared/ui/tab/BorderedTab";
import Gallery from "./Gallery";
import Overview from "./Overview";
import ProjectProgress from "./ProjectProgress";

const options = ["Milestones", "Gallery"];
const getOverView = (listing) => {
    return [
        {
            name: "Min. Investment",
            value: `₹${listing?.listingMinLimit}`,
        },
        {
            name: "Tenure",
            value: `${listing.investmentTerms[0]?.installments / 12} years`,
        },
        {
            name: "IRR",
            value: `${listing?.investmentTerms[0].targetedIRRPercentage}%`,
        },
    ];
};

const Summary = ({
    projectHidden = false,
    isScrollDisabled,
    listing,
    buttonHidden = false,
}) => {
    const [currentTab, setCurrentTab] = useState(0);
    let plants = listing?.plants[0];
    return (
        <div>
            <div
                className={cn(
                    "hidden lg:flex flex-wrap   xl:flex-nowrap justify-between gap-3 mb-2",
                    {
                        "items-stretch ": projectHidden,
                    }
                )}
            >
                <div className=" w-full  xl:w-[50%]">
                    <div className="p-1.6 border border-gray-400 rounded-2">
                        <P className="text-gray mb-0.8  text-base md:text-sm xl:text-sm">
                            {projectHidden
                                ? "Project Milestones"
                                : "MileStones"}
                        </P>
                        {projectHidden && <Line />}
                        <div className=" pt-4 ">
                            <ProjectProgress listing={listing} />
                        </div>
                        <Line />
                        <div className="grid grid-cols-2 w-full">
                            <div>
                                <P className="text-xs text-gray-550 mb-1.6 md:text-xs xl:text-xs">
                                    Funding Started
                                </P>
                                <P className="text-sm text-gray md:text-sm xl:text-sm">
                                    {moment(listing?.startDate * 1000).format(
                                        "Do MMMM yyyy"
                                    )}
                                </P>
                            </div>

                            <div className="text-right">
                                <P className="text-xs text-gray-550 mb-1.6 md:text-xs xl:text-xs">
                                    Funding End
                                </P>
                                <P className="text-sm text-gray md:text-sm xl:text-sm">
                                    {moment(listing?.endDate * 1000).format(
                                        "Do MMMM yyyy"
                                    )}
                                </P>
                            </div>
                        </div>

                        {!projectHidden && (
                            <>
                                <div className="flex rounded-full mt-1.6 p-1 items-center justify-center gap-1 bg-orange-200">
                                    <img
                                        className="h-3"
                                        src={funding}
                                        alt="funding"
                                    />
                                    <P className="text-orange">
                                        Funding plans are completed
                                        {/* @TODO => fix this @habib610 Sat November 30,2024 */}
                                    </P>
                                </div>
                                <div className="h-px bg-gray-400 my-1.6"></div>
                                <div className="grid grid-cols-2 w-full">
                                    <div>
                                        <P className="text-xs text-gray-550 mb-1.6 md:text-xs xl:text-xs">
                                            Capital raised
                                        </P>
                                        <P className="text-sm text-gray md:text-sm xl:text-sm">
                                            ₹{" "}
                                            {getCurrencyAmount(
                                                listing.fundRaised
                                            )}
                                        </P>
                                    </div>
                                    <div className="text-right">
                                        <P className="text-xs text-gray-550 mb-1.6 md:text-xs xl:text-xs">
                                            Total investors
                                        </P>
                                        <P className="text-sm text-gray md:text-sm xl:text-sm">
                                            {" "}
                                            {
                                                listing?.investmentTerms[0]
                                                    ?.investorCount
                                            }
                                        </P>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="w-full xl:w-[50%]">
                    {!projectHidden ? (
                        <>
                            <div className={cn({ "w-full": !projectHidden })}>
                                <P className="text-gray mb-0.8  text-base md:text-sm xl:text-sm font-vietnam">
                                    Description
                                </P>

                                <P className="text-gray-550 mb-2.4 font-vietnam md:text-sm xl:text-sm">
                                    We are excited to present a promising
                                    investment opportunity in a{" "}
                                    {listing.connectionType
                                        .replaceAll("_", " ")
                                        .toLowerCase()}{" "}
                                    located in {plants?.city},{" "}
                                    {listing?.plants[0].state}. We are seeking
                                    to raise{" "}
                                    <span className="font-medium text-gray-600">
                                        {getCurrencyAmount(listing.fundLimit)}
                                    </span>{" "}
                                    lakh for a solar project with a capacity of{" "}
                                    <span className="font-medium text-gray-600">
                                        {Number(
                                            plants?.capacity / 1000
                                        ).toFixed(0)}
                                        KWp,
                                    </span>{" "}
                                    situated at the {plants?.name} in{" "}
                                    {plants?.city}, {plants?.state}.
                                </P>
                            </div>

                            {!projectHidden && (
                                <div className="w-full">
                                    <Overview data={getOverView(listing)} />

                                    <div className="flex justify-between w-full bg-gray-50 rounded-sm p-1.6 my-1.6">
                                        <div>
                                            <H4 className="font-vietnam md:text-base xl:text-base">
                                                {plants?.name}
                                            </H4>
                                            <div className="text-base flex  items-center  gap-0.2 ">
                                                <div className="h-2.4 mr-0.8">
                                                    <img
                                                        src={mapMarker}
                                                        alt="location"
                                                    />
                                                </div>
                                                <P className=" font-vietnam inline-block md:text-sm xl:text-sm ">
                                                    {`${plants?.city}, ${plants?.state}`}
                                                </P>
                                            </div>
                                        </div>
                                        <div>
                                            <H4 className="md:text-base xl:text-base font-medium">
                                                {plants?.capacity / 1000} KWp
                                            </H4>
                                            <P className="text-base md:text-sm xl:text-sm">
                                                Plant Size
                                            </P>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="p-1.6 border border-gray-400 rounded-2">
                            <P className="text-gray mb-0.8  text-base md:text-sm xl:text-sm">
                                Funding Details
                            </P>
                            <Line />
                            <Overview data={getOverView(listing)} />
                            <div className="flex rounded-full mt-1.6 p-1 items-center justify-center gap-1 bg-green-50">
                                <img
                                    className="h-3"
                                    src={funding}
                                    alt="funding"
                                />
                                <P className="text-green-400">
                                    Funding plans are completed
                                    {/* @TODO => fix this @habib610 Sat November 30,2024 */}
                                </P>
                            </div>
                            <div className="h-px bg-gray-400 my-1.6"></div>
                            <div className="grid grid-cols-2 w-full">
                                <div>
                                    <P className="text-xs text-gray-550 mb-1.6 md:text-xs xl:text-xs">
                                        Capital raised
                                    </P>
                                    <P className="text-sm text-gray md:text-sm xl:text-sm">
                                        ₹{" "}
                                        {getCurrencyAmount(listing.fundRaised)}
                                    </P>
                                </div>
                                <div className="text-right">
                                    <P className="text-xs text-gray-550 mb-1.6 md:text-xs xl:text-xs">
                                        Total investors
                                    </P>
                                    <P className="text-sm text-gray md:text-sm xl:text-sm">
                                        {" "}
                                        {
                                            listing?.investmentTerms[0]
                                                ?.investorCount
                                        }
                                    </P>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="lg:hidden lg:justify-between lg:items-start lg:gap-3">
                <div className={cn({ "lg:w-[50%]": !projectHidden })}>
                    <P className="text-gray mb-0.8  text-base md:text-sm xl:text-sm font-vietnam">
                        Description
                    </P>

                    <P className="text-gray-550 mb-2.4 font-vietnam md:text-sm xl:text-sm">
                        We are excited to present a promising investment
                        opportunity in a{" "}
                        {listing.connectionType
                            .replaceAll("_", " ")
                            .toLowerCase()}{" "}
                        located in {plants?.city}, {listing?.plants[0].state}.
                        We are seeking to raise{" "}
                        <span className="font-medium text-gray-600">
                            {getCurrencyAmount(listing.fundLimit)}
                        </span>{" "}
                        lakh for a solar project with a capacity of{" "}
                        <span className="font-medium text-gray-600">
                            {Number(plants?.capacity / 1000).toFixed(0)}KWp,
                        </span>{" "}
                        situated at the {plants?.name} in {plants?.city},{" "}
                        {plants?.state}.
                    </P>
                </div>

                {!projectHidden && (
                    <div className="w-full lg:w-[50%]">
                        <Overview data={getOverView(listing)} />

                        <div className="flex justify-between w-full bg-gray-50 rounded-sm p-1.6 my-1.6">
                            <div>
                                <H4 className="font-vietnam md:text-base xl:text-base">
                                    {plants?.name}
                                </H4>
                                <div className="text-base flex  items-center  gap-0.2 ">
                                    <div className="h-2.4 mr-0.8">
                                        <img src={mapMarker} alt="location" />
                                    </div>
                                    <P className=" font-vietnam inline-block md:text-sm xl:text-sm ">
                                        {`${plants?.city}, ${plants?.state}`}
                                    </P>
                                </div>
                            </div>
                            <div>
                                <H4 className="md:text-base xl:text-base font-medium">
                                    {plants?.capacity / 1000} KWp
                                </H4>
                                <P className="text-base md:text-sm xl:text-sm">
                                    Plant Size
                                </P>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="p-1.6 border lg:hidden border-gray-400 rounded-2">
                <BorderedTab
                    className="mb-4"
                    onTabClick={(i) => setCurrentTab(i)}
                    options={options}
                    currentTab={currentTab}
                    tabClass="w-1/2"
                />
                {currentTab === 0 ? (
                    <div>
                        <ProjectProgress listing={listing} />

                        <Line />
                        <div className="grid grid-cols-2 w-full">
                            <div>
                                <P className="text-xs text-gray-550 mb-1.6">
                                    Funding Started
                                </P>
                                <P className="text-sm text-gray">
                                    {moment(listing.startDate * 1000).format(
                                        "Do MMMM YYYY"
                                    )}
                                </P>
                            </div>
                            <div className="text-right">
                                <P className="text-xs text-gray-550 mb-1.6">
                                    Funding End
                                </P>
                                <P className="text-sm text-gray">
                                    {moment(listing.endDate * 1000).format(
                                        "Do MMMM YYYY"
                                    )}
                                </P>
                            </div>
                        </div>

                        <div className="flex rounded-full mt-1.6 p-1 items-center justify-center gap-1 bg-orange-200">
                            <img className="h-3" src={funding} alt="funding" />
                            <P className="text-orange">
                                Funding plans are completed
                                {/* @TODO => fix this @habib610 Sat November 30,2024 */}
                            </P>
                        </div>
                        <div className="h-px bg-gray-400 my-1.6"></div>
                        <div className="grid grid-cols-2 w-full">
                            <div>
                                <P className="text-xs text-gray-550 mb-1.6">
                                    Capital raised
                                </P>
                                <P className="text-sm text-gray">
                                    ₹ {getCurrencyAmount(listing.fundRaised)}
                                </P>
                            </div>
                            <div className="text-right">
                                <P className="text-xs text-gray-550 mb-1.6">
                                    Total investors
                                </P>
                                <P className="text-sm text-gray">
                                    {" "}
                                    {listing?.investmentTerms[0]?.investorCount}
                                </P>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Gallery images={listing?.images} />
                )}
            </div>
        </div>
    );
};

export default Summary;
