import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import SplitType from "split-type";
import cn from "../../lib/cn";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H1 from "../../shared/typography/H1";
import H2 from "../../shared/typography/H2";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";

const SrotBenefit = ({ containerClass }) => {
    const textRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const char = textRef.current;
        const text = new SplitType(char, { types: "words,chars" });

        gsap.fromTo(
            text.chars,
            {
                opacity: 0.3,
            },
            {
                opacity: 1,
                stagger: 0.02,
                scrollTrigger: {
                    trigger: char,
                    start:
                        document.body.clientWidth < 600 ? "top 50%" : "top 90%",
                    end: "top 20%",
                    scrub: true,
                    markers: false,
                },
            }
        );

        return () => {
            ScrollTrigger.getAll().forEach((trigger) => {
                trigger.kill();
            });
        };
    });

    return (
        <Section className="bg-black mb-0 lg:mb-0 xl:mb-0 2xl:mb-0">
            <Container>
                <div className={cn(containerClass)}>
                    <div className="flex flex-col gap-3 py-6 xl:py-[12rem] w-full xl:w-[80%]">
                        <div>
                            <P className="text-white border border-white text-center p-1 rounded-full inline-block">
                                Why SROT?
                            </P>
                        </div>

                        <div className=" hidden  lg:flex gap-2 justify-between md:justify-start items-center">
                            <H2 className="text-white">Lower Risk</H2>
                            <H1 className="text-white">Higher Returns</H1>
                        </div>

                        <div className="  lg:hidden gap-2 justify-between md:justify-start items-center">
                            <H2 className="text-white">Lower Risk</H2>
                            <H1 className="text-white">Higher Returns</H1>
                        </div>
                        <H3 className=" text-2xl font-nunito  font-light">
                            <span ref={textRef} className="text-white">
                                Invest with Srot for a 20% IRR on pre-leased
                                solar assets, enjoying monthly payouts and lower
                                risk compared to bank deposits, real estate,
                                stocks, and bonds. By choosing Srot, you not
                                only secure strong financial returns but also
                                contribute to fighting climate change and making
                                a positive difference for our planet.
                            </span>
                        </H3>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default SrotBenefit;
