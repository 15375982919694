import React from "react";
import { IoMdCheckmark } from "react-icons/io";
import { employee, impactSolar } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";

let goals = [
    "Promote sustainable energy adoption.",
    "Reduce global carbon emissions.",
    "Empower communities through renewable energy",
];
const OurVision = () => {
    return (
        <Section>
            <Container>
                <div className="flex flex-wrap justify-between gap-y-2">
                    <div className=" w-full lg:w-5/12  xl:w-5/12">
                        <H2 className="mb-1.2 md:mb-2 xl:mb-2.8">Our Vision</H2>
                        <P className="text-lg xl:text-2xl font-light">
                            Clean energy for ALL <br /> Fractional solar
                            ownership for financial growth & environmental
                            sustainability
                        </P>

                        <H2 className="mb-1.2 md:mb-2 xl:mb-2.8 mt-2.8 xl:mt-4">
                            Our goals
                        </H2>
                        {goals.map((item, index) => (
                            <div
                                className="flex flex-nowrap gap-1 xl:gap-2 items-center mb-1 xl:mb-2"
                                key={index}
                            >
                                <div className="flex items-center justify-center p-0.2 xl:p-0 w-1.8 h-1.8 md:w-2.4 md:h-2.4 xl:w-4 xl:h-4 rounded-full bg-primary text-white text-xxl">
                                    <IoMdCheckmark />
                                </div>
                                <P className="font-thin text-lg md:text-xl xl:text-xxl flex-1 ">
                                    {item}
                                </P>
                            </div>
                        ))}
                    </div>
                    <div className="w-full lg:w-6/12  xl:w-6/12 ">
                        <div className="relative">
                            <div className="flex gap-4">
                                <img
                                    src={employee}
                                    className="w-8/12 h-full"
                                    alt="employee"
                                />
                                <img
                                    src={impactSolar}
                                    className="w-3/12  h-full"
                                    alt="impact_solar"
                                />
                            </div>
                            <div className="absolute right-0 w-[80%] top-[40%] h-4  flex flex-col items-end ">
                                <div className="py-1 px-1.6  xl:py-1.8 xl:px-2.4 2xl:py-2.4 2xl:px-3.2 bg-primary/50 backdrop-blur-lg rounded-sm xl:rounded-2">
                                    <P className=" text-white text-xs 2xl:text-2xl font-thin">
                                        Innovation + transparency = accessible,
                                        profitable renewable energy investments
                                    </P>
                                </div>{" "}
                                <div className="py-1 px-1.6 xl:py-1.8 xl:px-2.4 2xl:py-2.4 2xl:px-3.2 bg-primary/50 backdrop-blur-lg rounded-sm xl:rounded-2 mt-2 md:mt-4">
                                    <P className="text-white text-xs 2xl:text-2xl font-thin">
                                        Together, we build a greener future.
                                        #SolarEnergy #Sustainability #Investment
                                    </P>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default OurVision;
