import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";

import { emailIcon, phoneIcon } from "../../resources/js/icons";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import ReusableMap from "../../shared/ui/Map";
import SliderButton from "../../shared/ui/Slider/SliderButton";

export const MapContactUs = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const Maps = [
        {
            id: 1,
            address:
                "Hypersrot Technologies, Office No 6, 3rd Floor , Tulsi Green Balewadi High Street Road, Laxman Nagar, Pune, Maharashtra, 411045",
            title: "Pune",
            phoneNumber: "9970079570",
            email: "invest@srot.app",
            center: {
                lat: 18.56954272860635,
                lng: 73.77028281208628,
            },
        },
        {
            id: 2,
            address:
                "Hypersrot Technologies Private Limited, 1207/343/95H, HSR MAIN ROAD, 7TH SECTOR, HSR BANGALORE 560102",
            title: "Bangalore",
            phoneNumber: "9970079570",
            email: "invest@srot.app",
            center: {
                lat: 12.909707872352472,
                lng: 77.63528943692823,
            },
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const previousSlide = () => {
        if (currentSlide === 0) {
            setCurrentSlide(Maps.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const nextSlide = () => {
        if (currentSlide === Maps.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const AddressCard = ({ title, address, phoneNumber, email }) => (
        <div className="flex flex-col gap-2.4">
            <div className="flex flex-col gap-1.6">
                <H4
                    className={
                        "text-base font-medium text-black lg:text-2.8xl "
                    }
                >
                    {title}
                </H4>
                <p className="text-xs font-nunito  md:text-sm lg:text-base text-gray-secondary">
                    {address}
                </p>
            </div>
            <div className="flex flex-row md:flex-col gap-0.8 xl:gap-2">
                <div className="flex gap-0.8 xl:gap-1">
                    <img className="h-2" src={phoneIcon} alt={"Phone number"} />
                    <P className={" text-xs text-gray-secondary  lg:text-lg"}>
                        +91 {phoneNumber}
                    </P>
                </div>
                <div className="flex gap-0.8 xl:gap-1">
                    <img className="h-2" src={emailIcon} alt={"Email"} />
                    <P className={"text-xs text-gray-secondary lg:text-lg"}>
                        {email}
                    </P>
                </div>
            </div>
        </div>
    );

    return (
        <Section>
            <Container>
                <div className="flex flex-col-reverse lg:flex-row gap-x-9 gap-y-3 justify-start">
                    <div className="md:w-[85%]">
                        <CarouselComponent
                            selectedItem={currentSlide}
                            onChange={setCurrentSlide}
                            showArrows={false}
                            showStatus={false}
                            showIndicators={false}
                            showThumbs={false}
                            interval={3000}
                            swipeable={true}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={50}
                            emulateTouch={true}
                        >
                            {Maps.map((map, index) => (
                                <ReusableMap
                                    key={index}
                                    address={map.address}
                                    callback={() => setIsLoaded(true)}
                                    center={map.center}
                                />
                            ))}
                        </CarouselComponent>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <H2>Our Addresses</H2>
                        <div className="flex flex-col gap-3.2">
                            {Maps.map((map, index) => (
                                <AddressCard
                                    key={index}
                                    title={map.title}
                                    address={map.address}
                                    phoneNumber={map.phoneNumber}
                                    email={map.email}
                                />
                            ))}
                        </div>
                        <SliderButton
                            currentSlide={currentSlide}
                            onPrev={previousSlide}
                            onNext={nextSlide}
                            data={Maps}
                            className={"justify-start"}
                        />
                    </div>
                </div>
            </Container>
        </Section>
    );
};
