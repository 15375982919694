import React, { useState } from "react";
import styled from "styled-components";
import Spacing from "../components/space/Space";
import { faqs } from "../constants/faqData";
import Scroller, { HeaderHeight } from "../services/scroller";
import FAQSearchBox from "./FAQSearchBox";
import FAQSections from "./FAQSections";

const Container = styled.div`
    padding: ${HeaderHeight.default}
        ${(props) => props.theme.spacing.default.horizontal} 0 0;
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        padding: ${HeaderHeight.smallLaptop}
            ${(props) => props.theme.spacing.default.horizontal} 0;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding: ${HeaderHeight.tablet}
            ${(props) => props.theme.spacing.default.horizontal} 0;
    }
`;

const FrequentQuestion = () => {
    const [queryString, setQueryString] = useState("");

    const handleSearch = (e) => {
        setQueryString(e.target.value);
    };

    const navSectionHeaders = faqs.map((faq) => ({
        name: faq.title.toUpperCase(),
        id: faq.id,
    }));
    return (
        <Scroller
            navSectionHeaders={navSectionHeaders}
            rootMargin="0% 0% -75% 0%"
            threshold="0.0"
        >
            {(visibilityHandler) => (
                <Container>
                    <Spacing margin={["90px 0px 60px"]}>
                        <div>
                            <FAQSearchBox
                                value={queryString}
                                onChange={handleSearch}
                            />
                        </div>
                    </Spacing>
                    <FAQSections
                        visibilityHandler={visibilityHandler}
                        questions={faqs}
                        queryString={queryString}
                    />
                </Container>
            )}
        </Scroller>
    );
};

export default FrequentQuestion;
