import { useEffect, useState } from "react";

const useHeaderScroll = () => {
    const [position, setPosition] = useState(window.scrollY);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            let moving = window.scrollY;
            if (Math.abs(moving - position) > 20) {
                setVisible(position > moving);
                setPosition(moving);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    return { visible };
};

export default useHeaderScroll;
