import React from "react";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";

import StreamlineTree from "../../../portfoilo/mobile/StreamlineTree";
import LearnMore from "../LearnMore";
import NewOpportunities from "../NewOpportunities";
import MyInvestment from "./MyInvestment";

const ExistingUser = ({ portfolioData, profileData }) => {
    let investments = portfolioData?.data?.portfolio?.investments;

    let getTotalSolarAssets = (investments) => {
        let total = 0;
        if (investments?.length && investments.length > 0) {
            investments.forEach((el) => {
                total += Number(el?.solarAssetOwned);
            });
        }

        return total;
    };
    let solarAssetOwned = getTotalSolarAssets(investments);
    return (
        <div className="bg-white font-vietnam">
            <div className="bg-black-600 rounded-b-[3rem] pt-5 mt-4 ">
                <div className="flex gap-0.6  items-center mx-3">
                    <P className="text-gray-550 text-base">Good Morning,</P>
                    <H4 className="text-white text-lg">{profileData?.name}</H4>
                </div>
                <div className="flex flex-col gap-0.5 mx-3 mt-2">
                    <P className="text-gray-550 text-sm">Portfolio Value</P>
                    <H4 className="text-white font-medium text-xl">
                        ₹ {portfolioData?.data?.portfolio?.portfolioValue}
                    </H4>
                </div>
                <div className="px-2 relative top-3">
                    <StreamlineTree
                        className="bg-linear-blue-white"
                        headingClass="text-white"
                        descriptionClass="font-semibold"
                        subText="text-white"
                        solarAssetOwned={solarAssetOwned}
                    />
                </div>
            </div>
            <div className="mt-5">{/* <Points /> */}</div>
            {/* <RewardsList /> */}
            {/* <OfferCarousel /> */}
            <NewOpportunities />
            <MyInvestment investmentData={portfolioData} />
            <LearnMore />
        </div>
    );
};

export default ExistingUser;
