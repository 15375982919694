import React, { useEffect, useRef } from "react";
import P from "../../../shared/typography/P";

const ContactDetails = ({ onClose }) => {
    const popupRef = useRef(null);

    // Close the popup when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div
                ref={popupRef}
                className="bg-white p-2 mx-3 rounded-3xl shadow-lg"
            >
                <P className=" font-bold">Contact Support</P>
                <P className="my-1 text-xs">
                    Customer Support No :
                    <span className="font-bold text-sm"> +91 1234567889</span>
                </P>
                <P className="text-xs">
                    Customer Care timing :
                    <span className="font-bold text-sm">
                        {" "}
                        10 am - 6 pm (Mon - Sun)
                    </span>
                </P>
                <div
                    onClick={() => null}
                    className=" bg-black-600 mt-1 text-white text-sm border text-center w-full border-blue-700 font-normal py-1 px-3 rounded-full "
                >
                    Call Us
                </div>
            </div>
        </div>
    );
};

export default ContactDetails;
