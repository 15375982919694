import loginImg from "../assets/images/Login_image.png";
import loginBg from "../assets/images/loginBg.png";
const loginData = {
    background: {
        bg: loginBg,
        image: loginImg,
    },
    subHeadings: [
        "Think ",
        "Invest ",
        "Save nature while earning attractive returns..!!",
    ],
    inlineText: "Green..",
};

export { loginData };
