import React from "react";
import { useHistory } from "react-router-dom";
import { PROJECTS } from "../../../../constants/routes";
import cn from "../../../../lib/cn";
import { upArrowLight } from "../../../../resources/js/icons";
import P from "../../../../shared/typography/P";

const InvestmentCard = ({
    status,
    companyName,
    amount,
    investment,
    percentage,
    id,
}) => {
    const history = useHistory();
    return (
        <div
            role="button"
            onClick={() => {
                id && history.push(`${PROJECTS}/${id}`);
            }}
            className={cn(
                "font-vietnam bg-linear-bluish-black flex mx-0.1 justify-between text-start items-center rounded-3xl h-full  p-1.5 ",
                { "hover:cursor-pointer": id }
            )}
        >
            <div className="flex flex-col justify-start gap-1 ">
                <div>
                    <span
                        className={`text-xs mb-2 px-1 py-0.5 rounded-3xl ${
                            status === "PAID"
                                ? "bg-green-50 text-green-500"
                                : "bg-red-100 text-red"
                        }`}
                    >
                        {status}
                    </span>
                </div>
                <h2 className="font-medium text-sm text-white">
                    {companyName}
                </h2>
                <P className="text-white font-vietnam text-sm md:text-sm xl:text-sm ">
                    Inv - ₹ {investment.toFixed(2)}
                </P>
            </div>

            <div className="flex flex-col justify-start gap-1">
                <p className="text-sm text-gray-500">Pending Payouts</p>
                <p className="font-medium text-pretty w-full text-sm font-vietnam text-white flex-1 items-center">
                    ₹ {amount}
                </p>

                <div className="text-white font-vietnam flex items-center gap-0.6">
                    <span className="h-1.5">
                        <img src={upArrowLight} alt="Arrow" />
                    </span>
                    <P className="text-white text-sm md:text-sm xl:text-sm">
                        {percentage}%
                    </P>
                </div>
            </div>
        </div>
    );
};

export default InvestmentCard;
