import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";
import { PORTFOLIO } from "../../../../constants/routes";
import cn from "../../../../lib/cn";
import P from "../../../../shared/typography/P";
import SubHeading from "../../../../shared/typography/SubHeading";
import PillSlider from "../../../../shared/ui/Slider/PillSlider";
import InvestmentCard from "./InvestmentCard";

const MyInvestment = ({ height, className, investmentData }) => {
    const history = useHistory();
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    return (
        <div className={cn("p-3 lg:p-0", className)}>
            <div className="flex justify-between items-center">
                <SubHeading className=" text-base md:!text-base xl:!text-base font-semibold lg:font-semibold xl:font-semibold">
                    My Investments
                </SubHeading>
                <P
                    role="button"
                    onClick={() => history.push(PORTFOLIO)}
                    className="underline text-sm md:text-sm xl:text-sm text-blue-500 cursor-pointer"
                >
                    View All
                </P>
            </div>

            <div className="">
                <div className=" py-1">
                    <CarouselComponent
                        selectedItem={currentSlide}
                        onChange={setCurrentSlide}
                        showArrows={false}
                        showStatus={false}
                        showThumbs={false}
                        showIndicators={false}
                        autoPlay={true}
                        infiniteLoop={true}
                        interval={3000}
                        stopOnHover={true}
                        transitionTime={500}
                        swipeable={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        emulateTouch={true}
                    >
                        {investmentData?.data?.portfolio?.investments.map(
                            (data, index) => (
                                <div key={index} className="h-full">
                                    <InvestmentCard
                                        status={data?.status}
                                        companyName={data.name}
                                        amount={data.monthlyPayout}
                                        investment={data.investmentAmount}
                                        percentage={data.irr}
                                        id={data?.listingId}
                                    />
                                </div>
                            )
                        )}
                    </CarouselComponent>
                </div>
                <div className="flex justify-center">
                    <PillSlider
                        currentSlide={currentSlide}
                        className={""}
                        data={investmentData?.data?.portfolio?.investments}
                        handleSlideChange={handleSlideChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default MyInvestment;
