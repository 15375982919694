import React, { useState } from "react";
import useSortedPayoutsObjects from "../../../../../hooks/useSortedPayoutsObjects";
import Container from "../../../../../shared/Container";
import LinedTab from "../../../../../shared/ui/tab/LinedTab";
import PayoutItem from "./PayoutItem";

const options = ["Completed Payouts", "Upcoming Payouts"];

const PayoutsInvestment = ({ isScrollDisabled, payoutList }) => {
    const [currentTab, setCurrentTab] = useState(1);

    const { completedPayouts, upcomingPayouts } =
        useSortedPayoutsObjects(payoutList);

    let payouts = currentTab === 0 ? completedPayouts : upcomingPayouts;

    return (
        <div className="lg:hidden">
            <Container className=" py-1 lg:px-5 font-vietnam ">
                <LinedTab
                    className="mb-0 pb-1  bg-white"
                    data={options}
                    activeTab={currentTab}
                    onClick={setCurrentTab}
                />

                <div
                    className={`flex flex-col space-y-4 max-h-screen xl:max-h-[650px]  py-1 pb-[350px] xl:pb-4 ${
                        isScrollDisabled ? "" : "overflow-y-auto"
                    }`}
                >
                    <PayoutItem payouts={payouts} />
                </div>
            </Container>
        </div>
    );
};

export default PayoutsInvestment;
