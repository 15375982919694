import moment from "moment";
import React from "react";
import {
    IoAlertCircleOutline,
    IoArrowUpCircleOutline,
    IoCloseCircleOutline,
} from "react-icons/io5";
import cn from "../../../../../lib/cn";
import { check, close, info } from "../../../../../resources/js/icons";
import P from "../../../../../shared/typography/P";
import SubHeading from "../../../../../shared/typography/SubHeading";
import Accordion from "../../../../../shared/ui/Accordian/Accordian";

const PayoutItem = ({ payouts, name }) => {
    return (
        <div>
            {Object.keys(payouts).map((item, index) => (
                <div key={index} className="pb-1 ">
                    <Accordion
                        wrapperClass="py-0 mb-0 mt-0 px-0 "
                        isOpen={index === 0}
                        label={item}
                        labelWrapperClass="bg-gray-50 py-1 px-0.5 rounded"
                        contentMargin="mt-0"
                    >
                        {payouts[item].map((item, payoutIndex) => (
                            <div
                                key={payoutIndex}
                                className={`flex items-center py-1.6   border-b border-gray-4000}`}
                            >
                                <span className={`text-2xl mr-2`}>
                                    {item.payoutStatus === "PROCESSED" ? (
                                        <IoArrowUpCircleOutline className="text-green-300" />
                                    ) : item.payoutStatus === "CREATED" ? (
                                        <IoAlertCircleOutline className="text-blue-500" />
                                    ) : (
                                        <IoCloseCircleOutline className="text-red-300" />
                                    )}
                                </span>
                                <div className="flex-1 ">
                                    <div className="flex  items-center justify-between mb-0.8 ">
                                        <SubHeading className="font-medium text-gray  md:text-sm lg:!text-base xl:!text-base ">
                                            {item?.name
                                                ? item.name
                                                : name
                                                ? name
                                                : ""}
                                        </SubHeading>
                                        <SubHeading
                                            className={cn(
                                                `md:text-sm lg:!text-sm xl:!text-sm flex py-0.5 rounded-3xl px-1 gap-0.5 items-center`,
                                                {
                                                    "bg-green-50 text-green-400":
                                                        item.payoutStatus ===
                                                        "PROCESSED",

                                                    "bg-gray-50 text-blue-500":
                                                        item.payoutStatus ===
                                                        "CREATED",
                                                    "text-red-200 bg-red-100":
                                                        item.type === "FAILED",
                                                }
                                            )}
                                        >
                                            <img
                                                className="h-1.6"
                                                src={
                                                    item.payoutStatus ===
                                                    "CREATED"
                                                        ? info
                                                        : item.payoutStatus ===
                                                          "PROCESSED"
                                                        ? check
                                                        : close
                                                }
                                                alt="type_icon"
                                            />
                                            {item.payoutStatus === "CREATED"
                                                ? "Pending"
                                                : item.payoutStatus ===
                                                  "PROCESSED"
                                                ? "Paid"
                                                : "Failed"}
                                        </SubHeading>
                                    </div>
                                    <div className="flex  items-center justify-between  gap-1">
                                        <P className="text-gray-500 text-sm md:text-sm xl:text-sm">
                                            {moment(
                                                item?.payout_date * 1000
                                            ).format("DD/MM/yyyy")}
                                        </P>
                                        <SubHeading className="mr-1 xl:font-semibold  md:text-sm lg:!text-base xl:!text-base">
                                            ₹ {item.gross_payout}
                                        </SubHeading>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Accordion>
                </div>
            ))}
        </div>
    );
};

export default PayoutItem;
