import moment from "moment";
import React from "react";
import cn from "../../../../lib/cn";
import { carbon, solar, streamLine } from "../../../../resources/js/icons";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";
import SubHeading from "../../../../shared/typography/SubHeading";

const LedgerDash = ({ onClick, data, nextUpcomingPayout, solarAssetOwned }) => {
    return (
        <div className="bg-black-600 p-2 rounded-2xl">
            <div className="w-full">
                <div className=" w-full gap-x-4 gap-y-2  flex-wrap xl:flex-nowrap  flex xl:gap-1 2xl:gap-5 items-start">
                    <div className="flex flex-1 flex-col gap-1 mb-1 p-1">
                        <div className="flex flex-col gap-2 ">
                            <div className="flex items-center ">
                                <div className="w-2 h-2  bg-blue-light  rounded-0.7 mr-1"></div>
                                <H4 className="text-white font-medium md:text-base xl:text-base  font-vietnam">
                                    Invested Capital
                                </H4>
                                <H4 className="text-white ml-auto md:text-lg xl:text-lg font-vietnam">
                                    ₹ {data?.investedCapital || 0}
                                </H4>
                            </div>
                            <div className="flex items-center ">
                                <div className="w-2 h-2  bg-gray-400  rounded-0.7 mr-1"></div>
                                <H4 className="text-white font-medium md:text-base xl:text-base  font-vietnam">
                                    Total Payout
                                </H4>
                                <H4 className="text-white ml-auto md:text-lg xl:text-lg font-vietnam">
                                    ₹ {data?.totalPayout}
                                </H4>
                            </div>
                            <div className="flex items-center">
                                <div className="w-2 h-2 bg-blue  rounded-0.7 mr-1"></div>
                                <H4 className="text-white font-medium md:text-base xl:text-base font-vietnam">
                                    Distributed Payout
                                </H4>
                                <H4 className="text-white ml-auto md:text-lg xl:text-lg font-vietnam">
                                    ₹ {data?.distributedPayout}
                                </H4>
                            </div>
                        </div>
                        <div className="w-full h-[15px] bg-transparent border-0.5 border-gray-secondary  rounded-3xl relative ">
                            <div
                                className={cn(
                                    ` h-full rounded-3xl bg-linear-blue-white`
                                )}
                                style={{
                                    width: `${
                                        (data?.distributedPayout * 100) /
                                            data?.totalPayout ===
                                        0
                                            ? 3
                                            : (data?.distributedPayout * 100) /
                                              data?.totalPayout
                                    }%`,
                                }}
                            ></div>
                        </div>
                        <div className="w-full h-[15px] bg-transparent  rounded-3xl relative ">
                            <div
                                className={cn(
                                    ` h-full rounded-3xl bg-gray-400 `
                                )}
                                style={{
                                    width: `${100}%`,
                                }}
                            ></div>
                        </div>
                        <div className="w-full h-[15px] bg-transparent border-0.5 border-gray-secondary  rounded-3xl relative ">
                            <div
                                className={cn(
                                    ` h-full rounded-3xl bg-blue-light`
                                )}
                                style={{
                                    width: `${
                                        (data?.investedCapital * 100) /
                                            data?.totalPayout ===
                                        0
                                            ? 3
                                            : (data?.investedCapital * 100) /
                                              data?.totalPayout
                                    }%`,
                                }}
                            ></div>
                        </div>
                    </div>

                    <div className=" p-1.5 gap-0.5 text-center flex flex-col">
                        <H4 className="font-normal text-white font-vietnam text-nowrap md:text-base xl:text-base mb-3">
                            Portfolio Value
                        </H4>
                        <P className="font-semibold text-white  font-vietnam md:text-lg xl:text-lg text-nowrap">
                            ₹ {data?.portfolioValue}
                        </P>
                        <P className="text-white   md:text-base xl:text-base font-vietnam">
                            {data?.investments.length} Investments
                        </P>
                    </div>

                    {nextUpcomingPayout && (
                        <div className="flex flex-col items-end justify-between gap-0.5     border-l border-gray-secondary p-1.6 ">
                            <P className=" text-white md:text-base xl:text-base font-vietnam  mb-3 ">
                                Upcoming Payout
                            </P>
                            <SubHeading className="text-white md:text-xl lg:!text-xl xl:font-semibold font-vietnam">
                                ₹ {nextUpcomingPayout?.gross_payout}
                            </SubHeading>
                            <P className="text-white   md:text-base xl:text-base font-vietnam">
                                {moment(
                                    nextUpcomingPayout?.payout_date * 1000
                                ).format("DD/MM/yyyy")}
                            </P>
                        </div>
                    )}
                </div>
                <div className="bg-black-400 p-3 flex justify-between items-center">
                    <div className="flex gap-1.5 items-center">
                        <img src={solar} />
                        <div className="flex flex-col gap-0.5">
                            <P className="md:text-sm xl:text-sm text-white">
                                Solar Asset Owned
                            </P>
                            <P className="text-white">
                                {" "}
                                {solarAssetOwned.toFixed(2)}KWp
                            </P>
                        </div>
                    </div>
                    <div className="flex gap-1 items-center">
                        <img src={carbon} />
                        <div className="flex flex-col gap-0.5">
                            <P className="md:text-sm xl:text-sm text-white">
                                Co2 Offset Every Year
                            </P>
                            <P className="text-white">
                                {" "}
                                {`${Number(1.39 * solarAssetOwned).toFixed(
                                    2
                                )} Tonnes`}
                            </P>
                        </div>
                    </div>
                    <div className="flex gap-1 items-center">
                        <img src={streamLine} />
                        <div className="flex flex-col gap-0.5">
                            <P className="md:text-sm xl:text-sm text-white">
                                Equivalent to planting
                            </P>
                            <P className="text-white">{`${Math.round(
                                67.23 * solarAssetOwned
                            )} Trees`}</P>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LedgerDash;
