import moment from "moment";
import { useMemo } from "react";

const buildPayoutData = (transaction, individualPayouts, investmentId) => {
    if (!transaction?.length && !individualPayouts?.length) return [];

    if (individualPayouts) {
        const filteredPayouts = investmentId
            ? individualPayouts.filter(
                  (el) => Number(el?.investment_id) === Number(investmentId)
              )
            : individualPayouts;

        return filteredPayouts.reduce((currentArray, el) => {
            currentArray.push({
                name: "",
                payout_date: el?.payout_date,
                investment_id: el?.investment_id,
                payoutStatus: el?.status,
                payoutNumber: el?.payout_number,
                gross_payout: el?.gross_payout,
            });
            return currentArray;
        }, []);
    }

    let allTransactions = transaction.reduce((acc, el) => {
        if (el?.upcomingPayouts && el?.upcomingPayouts?.length > 0) {
            let newArray = el?.upcomingPayouts.reduce(
                (currentArray, currentEl) => {
                    if (
                        !investmentId ||
                        Number(currentEl?.investment_id) ===
                            Number(investmentId)
                    ) {
                        currentArray.push({
                            name: el?.name,
                            status: el.status,
                            payout_date: currentEl?.payout_date,
                            investment_id: currentEl?.investment_id,
                            payoutStatus: currentEl?.status,
                            payoutNumber: currentEl?.payout_number,
                            gross_payout: currentEl?.gross_payout,
                        });
                    }
                    return currentArray;
                },
                []
            );

            acc.push(...newArray);
        }
        return acc;
    }, []);

    return allTransactions;
};

const generatePayouts = (payoutList) => {
    let completedPayouts = {};
    let upcomingPayouts = {};

    if (!payoutList || payoutList?.length === 0) {
        return { completedPayouts, upcomingPayouts };
    }

    let sortedPayouts = payoutList.sort(
        (a, b) => a?.payout_date - b?.payout_date
    );

    const currentDateMs = new Date().getTime() / 1000;

    sortedPayouts.forEach((el, i) => {
        let dateFormat = moment.unix(el?.payout_date).format("MMM YYYY");
        if (currentDateMs < el?.payout_date) {
            if (!upcomingPayouts[dateFormat]) {
                upcomingPayouts[dateFormat] = [];
            }
            upcomingPayouts[dateFormat].push(el);
        } else {
            if (!completedPayouts[dateFormat]) {
                completedPayouts[dateFormat] = [];
            }
            completedPayouts[dateFormat].push(el);
        }
    });

    return { completedPayouts, upcomingPayouts };
};

/**
 *
 * @param {array} transaction - List of transactions
 * @param {array} individualPayouts - List of individual payouts
 * @param {string} [investmentId] - Optional ID to filter payouts
 * @returns {Object} { completedPayouts [], upcomingPayouts [] }
 */
const useSortedPayoutsObjects = (transaction, individualPayouts, payoutId) => {
    let payoutList = useMemo(
        () => buildPayoutData(transaction, individualPayouts, payoutId),
        [individualPayouts, transaction, payoutId]
    );
    return useMemo(() => generatePayouts(payoutList), [payoutList]);
};

export default useSortedPayoutsObjects;
