import React, { useCallback, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";

import { GET_SUPPORT_TICKET_QUERY_META_ENDPOINT } from "../../../../constants/routes";
import useNetwork from "../../../../hooks/useNetwork";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";
import PopupMeta from "../../PopupMeta";
import ChatContainer from "./Chat/ChatContainer";
import RaiseTicket from "./newTicket";
import TicketList from "./TicketList";
const Tickets = () => {
    const [ticket, setTicket] = useState(false);
    const [openTicket, setOpenTicket] = useState(null);
    const {
        data: meta,
        loading: metaLoading,
        error: metaError,
    } = useNetwork(GET_SUPPORT_TICKET_QUERY_META_ENDPOINT);

    const [ticketList, setTicketList] = useState([]);
    const pushLatestQueryHandler = useCallback(
        (newQuery) => {
            setTicketList([newQuery, ...ticketList]);
        },
        [ticketList]
    );
    const handleRaiseTicket = () => {
        setTicket(true);
        setOpenTicket(null);
    };

    const handleOpenTicket = (ticket) => {
        setOpenTicket(ticket);
        setTicket(false);
    };

    const handleCloseTicket = () => {
        setTicket(false);
        setOpenTicket(null);
    };
    const handleSubmit = (ticketData) => {
        setTicket(false);
    };
    return (
        <div className="font-vietnam">
            {ticket ? (
                <>
                    <div className="h-[470px] overflow-y-scroll lg:hidden">
                        <RaiseTicket
                            onSubmit={handleSubmit}
                            onClose={handleCloseTicket}
                        />
                    </div>
                    <div className={"hidden lg:flex"}>
                        <PopupMeta
                            isOpen={ticket}
                            onClose={() => setTicket((prev) => !prev)}
                            meta={meta?.data?.tickets || null}
                            metaLoading={metaLoading}
                            metaError={metaError}
                            onCreate={pushLatestQueryHandler}
                        />
                    </div>
                </>
            ) : openTicket ? (
                <>
                    <div className="flex items-center gap-1 mb-1">
                        <P
                            className="text-gray-550 text-lg "
                            onClick={() => handleCloseTicket()}
                        >
                            <MdKeyboardArrowLeft />
                        </P>
                        <H4 className="font-medium text-base ">Ticket Chat</H4>
                    </div>
                    <ChatContainer onRaiseTicket={handleRaiseTicket} />
                </>
            ) : (
                <>
                    <div className="flex justify-between items-center">
                        <H4 className="font-medium text-base">Tickets</H4>
                        <div
                            onClick={handleRaiseTicket}
                            className=" bg-white text-black text-sm border text-center border-blue-700 font-normal py-1 px-3 rounded-full "
                        >
                            Raise new Ticket
                        </div>
                    </div>
                    <TicketList onOpenTicket={handleOpenTicket} />
                </>
            )}
        </div>
    );
};

export default Tickets;
