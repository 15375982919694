import React from "react";

import { Link } from "react-router-dom";
import { LANDING } from "../../constants/routes";
import { logo } from "../../resources/js/images";
import AuthWrapper from "./AuthWrapper";
import SignUpPage from "./SignUpPage";

const SignUp = () => {
    return (
        <AuthWrapper
            heading="Get Started"
            text="Please create your account to get onboarded to our platform"
        >
            <div className="flex justify-center items-center h-full w-full">
                <div className="bg-white pt-3 md:p-1 box-border lg:p-3  flex flex-col gap-2 rounded-xl  w-full ">
                    <div className="flex justify-center items-center">
                        <Link
                            to={LANDING}
                            className="duration-500 transition-all"
                        >
                            <span className="sr-only">Hypersrot </span>
                            <img
                                src={logo}
                                className={` h-[20px] sm:h-[30px] w-auto transition-all ease-in-out duration-500`}
                                alt="HyperSrot_logo"
                            ></img>
                        </Link>
                    </div>

                    <SignUpPage />
                </div>
            </div>
        </AuthWrapper>
    );
};

export default SignUp;
