import moment from "moment";
import React from "react";
import { getCurrencyAmount } from "../../../helpers/helpers";
import cn from "../../../lib/cn";
import P from "../../../shared/typography/P";

const FundStatus = ({
    fundLimit,
    fundRaised,
    listingStatus,
    activationDate,
    className,
}) => {
    let remains = moment(activationDate * 1000).diff(moment(), "days");
    let endsIn =
        remains > 1
            ? `Ends in ${remains}${remains === 1 ? " day" : " days"}`
            : "Ended";
    return (
        <div className={cn("bg-black-600 rounded-sm relative", className)}>
            <div className="flex justify-between items-center mb-1.6 xl:mb-1 gap-2">
                <P className="text-gray-550 xl:text-base 2xl:text-base ">
                    {listingStatus}
                </P>
                <P className="text-orange-300   xl:text-base 2xl:text-base">
                    {endsIn}
                </P>
            </div>
            <div className="w-full h-[28px] bg-blue-600 rounded-3xl relative mb-1.6">
                <div
                    className={cn(` h-full rounded-3xl bg-linear-blue-white`)}
                    style={{
                        width: `${((fundRaised * 100) / fundLimit).toFixed(
                            2
                        )}%`,
                    }}
                ></div>
                <div className="absolute top-[50%] -translate-y-[50%] right-2">
                    <P className="text-white">{`${(
                        (fundRaised * 100) /
                        fundLimit
                    ).toFixed(1)}%`}</P>
                </div>
            </div>
            <div className="flex justify-between">
                <P className="text-gray-500 xl:text-sm 2xl:text-base font-vietnam">
                    {getCurrencyAmount(fundRaised)} Raised
                </P>
                <P className="text-gray-500 xl:text-sm 2xl:text-base font-vietnam">
                    Raising {getCurrencyAmount(fundLimit)}
                </P>
            </div>
        </div>
    );
};

export default FundStatus;
