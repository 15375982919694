import React, { useState } from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import {
    CLOSE_LOGIN_FORM,
    REMOVE_REDIRECT_PATH,
    SET_ERROR,
} from "../../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../../constants/regularExpression";
import { FORGET, SIGNUP } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { validateUserId } from "../../helpers/fieldValidation";
import { handleRedirection } from "../../helpers/routingHelper";
import { handleResentOTP } from "../../services/apiRequest";
import auth from "../../services/auth";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import LabeledInput from "../../shared/ui/Form/LabeledInput";
import OTP from "../../shared/ui/OTP";
import SwitchingTabs from "../../shared/ui/tab/SwitchingTabs";

const validateLoginForm = (formData, currentTab, getOtp) => {
    const newErrors = {};
    if (!formData.email.trim()) {
        newErrors.email = "UserId is required";
    } else if (
        !phoneRegEx.test(formData.email.trim()) &&
        !emailRegEx.test(formData.email.trim())
    ) {
        newErrors.email = "Invalid UserId";
    }
    if (currentTab === 1) {
        if (!formData.password.trim()) {
            newErrors.password = "Password is required";
        }
    } else if (getOtp) {
        if (!formData.otp.trim()) {
            newErrors.otp = "OTP is required";
        }
    }
    return newErrors;
};

const LoginPage = () => {
    const [currentTab, setCurrentTab] = useState(1);
    const { state, dispatch } = useContextState();

    const history = useHistory();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        otp: "",
    });
    const handleForgotPasswordClick = () => {
        history.push(FORGET);
    };
    const [otp, setOtp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };
    const closeModalHandler = () => {
        setFormData({ email: "", password: "", otp: "" });
        setErrors({});
        setLoading(false);
        setOtp(false);
        dispatch({ type: CLOSE_LOGIN_FORM });
    };

    const handleGetOTP = async () => {
        setLoading(true);
        let val = emailRegEx.test(formData.email)
            ? formData.email
            : `91${formData.email}`;
        try {
            await auth.loginOTP({
                username: val,
            });
            setOtp(true);
            toast.success("OTP has been sent", {
                containerId: "otpContainer",
            });

            setLoading(false);
        } catch (error) {
            setLoading(false);
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };
    const resentOtpHandler = async (mode) => {
        const validationErrors = validateUserId(formData.email);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            let credentials = emailRegEx.test(formData.email)
                ? formData.email
                : `91${formData.email}`;
            await handleResentOTP(mode, credentials, setLoading, dispatch);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateLoginForm(formData, currentTab, otp);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});

            if (currentTab === 1) {
                setLoading(true);

                try {
                    let val = emailRegEx.test(formData.email)
                        ? formData.email
                        : `91${formData.email}`;
                    await auth.login({
                        username: val,
                        password: formData.password,
                        otpEnable: false,
                    });

                    let profile = await auth.getUserProfile(dispatch);
                    let status = profile?.data?.investor_profile?.plant?.status;
                    const roles = profile?.data?.investor_profile?.roles;
                    handleRedirection(state, history, roles, status);
                    dispatch({
                        type: REMOVE_REDIRECT_PATH,
                    });
                    setLoading(false);
                    closeModalHandler();
                } catch (error) {
                    setLoading(false);

                    dispatch({
                        type: SET_ERROR,
                        payload: typeof error === "string" ? error : ERROR_MSG,
                    });
                }
            } else {
                if (otp) {
                    setLoading(true);

                    try {
                        let val = emailRegEx.test(formData.email.trim())
                            ? formData.email
                            : `91${formData.email}`;
                        await auth.login({
                            username: val,
                            password: formData.otp,
                            otpEnable: true,
                        });
                        let profile = await auth.getUserProfile(dispatch);
                        setLoading(false);
                        closeModalHandler();
                        let status =
                            profile?.data?.investor_profile?.plant?.status;
                        const roles = profile?.data?.investor_profile?.roles;
                        handleRedirection(state, history, roles, status);
                        dispatch({
                            type: REMOVE_REDIRECT_PATH,
                        });
                    } catch (error) {
                        setLoading(false);

                        dispatch({
                            type: SET_ERROR,
                            payload:
                                typeof error === "string" ? error : ERROR_MSG,
                        });
                    }
                } else {
                    handleGetOTP();
                }
            }
        }
    };

    return (
        <div>
            <P
                role="button"
                className=" text-2xl md:text-4.0xl xl:text-4.0xl xl:font-light"
                onClick={() => history.goBack()}
            >
                <IoIosArrowDropleft />
            </P>
            <div className="flex flex-col gap-2">
                <div className="flex flex-wrap flex-row gap-2  justify-between md:items-center">
                    <H4 className="font-semibold mt-1 text-nowrap">
                        Welcome Back!
                    </H4>
                    <SwitchingTabs
                        options={["OTP", "Password"]}
                        currentTab={currentTab}
                        currentActive={"!rounded-[3rem] bg-gray"}
                        notActive={"!rounded-[3rem]"}
                        onTabClick={(index) => setCurrentTab(index)}
                        className="shadow-sm "
                        border="!rounded-[3rem] lg:!rounded-[3rem] xl:rounded-[3rem]"
                    />
                </div>

                <LabeledInput
                    label={currentTab === 1 ? "Email" : "Email /Phone Number"}
                    value={formData?.email}
                    onChange={handleChange}
                    name={"email"}
                    type={"text"}
                    errorMsg={errors?.email}
                    required={true}
                    placeholder=""
                />
                {currentTab === 1 && (
                    <LabeledInput
                        label="Password"
                        inputWrapper="rounded-l-md w-full"
                        name={"password"}
                        onChange={handleChange}
                        iconChange={() => setShow((prev) => !prev)}
                        errorMsg={errors?.password}
                        type={show ? "text" : "password"}
                        icon={show ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    />
                )}
                {currentTab === 1 && (
                    <div
                        className="cursor-pointer flex justify-end"
                        onClick={() => handleForgotPasswordClick()}
                    >
                        <p className="underline text-blue text-xs md:text-sm">
                            Forgot Password
                        </p>
                    </div>
                )}
                {otp && currentTab === 0 && (
                    <OTP
                        otp={formData.otp}
                        onChange={handleChange}
                        error={errors?.otp ? true : false}
                        errorMessage={errors.otp}
                        handleResentOTP={resentOtpHandler}
                    />
                )}
                <Button
                    className={"bg-black text-white !rounded-[3rem] w-full"}
                    onClick={handleSubmit}
                >
                    {otp ? "Verify" : "Continue"}
                </Button>
                {/* <div className="flex gap-1 items-center">
                        <Line className={"w-1/3 bg-gray-800"} />
                        <P className="text-nowrap">or continue with</P>
                        <Line className={"w-1/3  bg-gray-800"} />
                    </div>
                    <div className="flex justify-center items-center gap-2">
                        { <div className="flex px-3 py-1 justify-center items-center border border-gray-light rounded-2xl">
                            <img src={facebook} alt="facebook" />
                        </div> }
                        <div className="flex px-3 py-1 justify-center items-center border border-gray-light rounded-2xl">
                            <img src={google} alt="google" />
                        </div>
                        <div className="flex px-3 py-1 justify-center items-center border border-gray-light rounded-2xl">
                            <img src={apple} alt="apple" />
                        </div> 
                    </div> */}

                <div className="flex items-center justify-center mb-2">
                    <P className="flex items-center gap-0.2">
                        Don’t have an account?
                        <span
                            className="text-blue underline"
                            role="button"
                            onClick={() => history.push(SIGNUP)}
                        >
                            Sign up
                        </span>
                    </P>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
