import React, { useState } from "react";
import useSortedPayoutsObjects from "../../../../hooks/useSortedPayoutsObjects";
import P from "../../../../shared/typography/P";
import PayoutItem from "./payouts/PayoutItem";

const DetailsPayout = ({ investments, individualPayouts, payoutId, name }) => {
    const [activeTab, setActiveTab] = useState(1);

    const { completedPayouts, upcomingPayouts } = useSortedPayoutsObjects(
        investments,
        individualPayouts,
        payoutId
    );

    let payouts = activeTab === 0 ? completedPayouts : upcomingPayouts;
    return (
        <div className="p-2.4 rounded-2 border border-gray-400 ">
            <div className="flex mb-4 gap-3 justify-between border-b border-b-blue-50 ">
                {["Completed Payouts", "Upcoming Payouts"].map((tab, i) => (
                    <div
                        key={i}
                        onClick={() => setActiveTab(i)}
                        className={`flex  hover:cursor-pointer items-center border-b-2 gap-1 pb-1 font-vietnam ${
                            activeTab === i
                                ? "text-blue-500  border-b-blue-500"
                                : "text-gray-500 border-b-transparent"
                        }`}
                    >
                        <P
                            className={`text-nowrap lg:text-sm xl:text-sm ${
                                activeTab === i
                                    ? "text-blue-500 "
                                    : "text-gray-500"
                            }`}
                        >
                            {tab}
                        </P>
                    </div>
                ))}
            </div>
            {/* @TODO => dropdown @habib610 Thu September 26,2024 */}
            <div className=" lg:overflow-y-auto  lg:max-h-[460px]">
                <PayoutItem payouts={payouts} name={name} />
            </div>
        </div>
    );
};

export default DetailsPayout;
