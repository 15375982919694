import { motion } from "framer-motion";
import React from "react";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import {
    translateContainer,
    wavyHeadings,
} from "../../shared/animation/constants/landingAnimation";
import SplitText from "../../shared/animation/typography/SplitText";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import ContactForm from "../../shared/ui/Form/ContactForm";

const contactData = {
    heading: {
        title: "Ready to make the switch?",
        content:
            "Discover more about our products, pricing, and plans by calling",
    },
    general: {
        title: "General Inquiry",
        content:
            "Fill out the form to receive a callback from our team and get further details.",
    },
    investor: {
        title: "Investor Inquiry",
        content:
            "Contact us at invest@srot.app to explore the investment opportunities.",
    },
};
const ContactUSForm = ({ enquiry = null, data = contactData }) => {
    return (
        <Section>
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="flex justify-between flex-wrap items-center  lg:items-start xl:gap-9 mb-6.4"
                >
                    <div className="w-full xl:w-[40%]">
                        {data?.heading && (
                            <>
                                {" "}
                                <SplitText
                                    heading="h2"
                                    variants={wavyHeadings}
                                    className={"mb-0.8 "}
                                >
                                    {data.heading.title}
                                </SplitText>
                                <P
                                    className={
                                        " text-gray-secondary text-base sm:text-lg  lg:pr-5"
                                    }
                                >
                                    {data.heading.content}
                                </P>
                            </>
                        )}

                        {enquiry}
                        <div className="mt-3.2 xl:mt-4">
                            {data?.general && (
                                <>
                                    {" "}
                                    <H4 className="mb-0.8">
                                        {data.general.heading}
                                    </H4>
                                    <P
                                        className={
                                            "mb-2.4 xl:mb-3.2 text-gray-secondary xl:w-[60%]"
                                        }
                                    >
                                        {data.general.content}
                                    </P>
                                </>
                            )}

                            {data?.investor && (
                                <>
                                    <H4 className={"mb-0.8"}>
                                        {data.investor.heading}
                                    </H4>
                                    <P
                                        className={
                                            "mb-3.2 lg:mb-4 text-gray-secondary xl:w-[60%]"
                                        }
                                    >
                                        {data.investor.content}
                                    </P>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="flex-1 xl:w-[55%] 2xl:w-1/2">
                        <ContactForm />
                    </div>
                </motion.div>
            </Container>
        </Section>
    );
};

export default ContactUSForm;
