import moment from "moment/moment";
import React from "react";
import {
    IoArrowDownCircleOutline,
    IoArrowUpCircleOutline,
    IoFilter,
} from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { PORTFOLIO } from "../../../../../constants/routes";
import cn from "../../../../../lib/cn";
import { check, close } from "../../../../../resources/js/icons";
import P from "../../../../../shared/typography/P";
import SubHeading from "../../../../../shared/typography/SubHeading";
import Accordion from "../../../../../shared/ui/Accordian/Accordian";

export const Payout = ({ item, isLast }) => {
    const history = useHistory();
    return (
        <div
            className={`flex justify-between items-center py-1.6  ${
                !isLast ? "border-b border-gray-4000" : ""
            }`}
            role="button"
            onClick={() => history.push(`${PORTFOLIO}/${item.id}`)}
        >
            <div className="flex items-center">
                <span
                    className={`text-2xl mr-2 bg-gray-60 rounded-full text-gray-550`}
                >
                    {item.type === "CREDIT" ? (
                        <IoArrowUpCircleOutline />
                    ) : (
                        <IoArrowDownCircleOutline />
                    )}
                </span>
                <div className="flex flex-col gap-0.5">
                    <SubHeading className="font-medium text-gray  md:text-sm lg:!text-base xl:!text-base">
                        {item?.name}
                    </SubHeading>
                    <P className="text-gray-500 text-sm md:text-sm xl:text-sm">
                        {moment(item?.date * 1000).format("DD/MM/yyyy")}
                    </P>
                </div>
            </div>
            <div className="flex flex-col items-end  gap-1">
                <SubHeading
                    className={cn(
                        `md:text-sm lg:!text-sm xl:!text-sm flex py-0.5 rounded-3xl px-1 gap-0.5 items-center`,
                        {
                            "bg-green-50 text-green-400":
                                item.type === "CREDIT",
                            "text-red-200 bg-red-100": item.type === "DEBIT",
                        }
                    )}
                >
                    <img
                        src={item.type === "CREDIT" ? check : close}
                        alt="type_icon"
                    />
                    {item.type}
                </SubHeading>
                <SubHeading className="mr-1 xl:font-semibold  md:text-sm lg:!text-base xl:!text-base">
                    {item.type === "CREDIT" ? "+" : "-"} ₹ {item.amount}
                </SubHeading>
            </div>
        </div>
    );
};

const PayoutList = ({ isScrollDisabled, onClick, transactions }) => {
    if (!transactions) return null;

    return (
        <div className=" font-vietnam px-2 lg:px-0 lg:mt-2">
            <div className="flex  justify-between items-center mb-2">
                <P className=" font-semibold md:text-base xl:text-base">
                    Transaction
                </P>
                <div className="flex text-2xl items-center gap-2 mt-2 lg:mt-0">
                    <IoFilter
                        className="hover:cursor-pointer"
                        onClick={onClick}
                    />
                    {/* <FiDownload /> */}
                </div>
            </div>

            <div
                className={`flex flex-col  space-y-2 lg:space-y-2 max-h-screen xl:max-h-[650px]  pb-[250px] xl:pb-4 
                     ${isScrollDisabled ? "" : "overflow-y-auto"}`}
            >
                {Object.keys(transactions).map((item, index) => (
                    <div key={index} className="pb-1 ">
                        <Accordion
                            wrapperClass="py-0 mb-0 mt-0 px-0 "
                            isOpen={true}
                            label={item}
                            labelWrapperClass="bg-gray-50 py-1 px-0.5 rounded"
                            contentMargin="mt-0"
                        >
                            {transactions[item].map(
                                (payOutItem, payoutIndex) => (
                                    <Payout
                                        key={payOutItem.id}
                                        item={payOutItem}
                                        isLast={
                                            transactions[item]?.length - 1 ===
                                            payoutIndex
                                        }
                                    />
                                )
                            )}
                        </Accordion>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PayoutList;
