import React from "react";

import PayoutGraph from "../../../portfoilo/mobile/portfolio-details/payouts/PayoutGraph";
import MyInvestment from "../ExistingUserMobile/MyInvestment";
import InvestmentCalculator from "../NewUser/InvestmentCalculator";

const RightSide = ({
    investmentHave,
    portfolioData,
    portfolioLoading,
    portfolioError,
}) => {
    const RewardData = [
        {
            id: 1,
        },
        {
            id: 2,
        },
        {
            id: 3,
        },
        {
            id: 4,
        },
    ];

    return (
        <div>
            {/* <Points /> */}
            {/* <div className="flex justify-between items-center mt-3 mb-1">
                <P className=" font-semibold xl:text-base md:text-black">
                    Rewards
                </P>
                <P className="underline xl:text-sm text-blue-500 cursor-pointer">
                    View All
                </P>
            </div> */}
            {/* <div className="mb-2 flex overflow-x-scroll gap-1">
                {RewardData.map((data, index) => (
                    <div className="bg-gray-400 py-2 px-3 rounded-t-3xl mx-1">
                        <img src={yellowRewards} className="h-9 mb-1" />
                        <P className="text-nowrap xl:text-sm">
                            Rewards {data.id}
                        </P>
                    </div>
                ))}
            </div> */}
            <PayoutGraph
                data={portfolioData}
                loading={portfolioLoading}
                error={portfolioError}
                height={"h-full"}
            />
            {investmentHave ? (
                <MyInvestment
                    investmentData={portfolioData}
                    height={" lg:h-[300px] xl:h-[150px]"}
                />
            ) : (
                <InvestmentCalculator />
            )}
        </div>
    );
};

export default RightSide;
