import React from "react";
import { termsOfService } from "../../resources/js/images";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";

import Section from "../../shared/Section";
import Hero from "../contact-us/Hero";
import Terms from "./terms";

const termContent = (
    <Section
        className={
            " flex justify-center items-center w-full sm:py-[2rem]  !mb-0 "
        }
    >
        <Container className="flex items-center justify-start ">
            <H1 className="text-white font-semibold ">Terms of Service</H1>
        </Container>
    </Section>
);
const TermsAndConditions = () => {
    return (
        <div>
            <Hero
                url={termsOfService}
                content={termContent}
                containerClass={"bg-black"}
                contentClass="bg-black/35 flex items-center mb-0"
                wrapperClass={"h-[306px] sm:h-[306px] xl:h-[378px] mb-0 "}
            />
            <Terms />
        </div>
    );
};

export default TermsAndConditions;
