import React, { useState } from "react";

import { LANDING } from "../../constants/routes";

import { IoIosArrowDropleft } from "react-icons/io";
import { Link, useHistory } from "react-router-dom";
import { logo } from "../../resources/js/images";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import AuthWrapper from "../signup/AuthWrapper";
import ForgotPasswordForm from "./ForgotPasswordForm";

const ResetPassword = () => {
    const [update, setUpdate] = useState(false);
    const UpdatePass = () => {
        setUpdate(!update);
    };
    const history = useHistory();
    return (
        <AuthWrapper
            heading="Reset!"
            text="Use your email id or phone number to reset your account"
        >
            <div className="flex justify-center items-center h-full w-full ">
                <div className="bg-white pt-3 md:p-1 box-border lg:px-3  flex flex-col gap-2 rounded-xl  w-full ">
                    <div className="flex justify-center items-center">
                        <Link
                            to={LANDING}
                            className="duration-500 transition-all"
                        >
                            <span className="sr-only">Hypersrot </span>
                            <img
                                src={logo}
                                className={` h-[20px] sm:h-[30px] w-auto transition-all ease-in-out duration-500`}
                                alt="HyperSrot_logo"
                            ></img>
                        </Link>
                    </div>
                    <P
                        role="button"
                        className=" text-2xl md:text-4.0xl xl:text-4.0xl xl:font-light"
                        onClick={() => history.goBack()}
                    >
                        <IoIosArrowDropleft />
                    </P>
                    <H4 className="font-semibold mt-1 my-0 text-nowrap">
                        Reset your Password
                    </H4>
                    <ForgotPasswordForm UpdatePass={UpdatePass} />
                </div>
            </div>
        </AuthWrapper>
    );
};

export default ResetPassword;
