import { Form, Formik } from "formik";
import React, { useState } from "react";
import { ERROR_MSG } from "../../constants/common";
import { phoneRegEx } from "../../constants/regularExpression";
import { FORGET_PASSWORD_ENDPOINT } from "../../constants/routes";
import request from "../../services/request";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";

import FormInput from "../../shared/ui/Form/FormInput";
import { loginWithOTPValidator } from "../../shared/ui/Form/schema";

const ForgotPasswordForm = () => {
    const [emailSent, setEmailSent] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values, actions) => {
        try {
            setEmailSent("");
            const endpoint = FORGET_PASSWORD_ENDPOINT;
            const credentials = phoneRegEx.test(values.username)
                ? `91${values.username}`
                : values.username;

            const body = {
                credentials,
            };

            setLoading(true);
            let res = await request.put({ endpoint, body });
            setEmailSent(res?.message || "Email has been sent");
            setLoading(false);
            setError("");
        } catch (error) {
            setError(typeof error === "string" ? error : ERROR_MSG);
            setLoading(false);
            setEmailSent("");
        }
    };

    return (
        <div className="mt-1">
            <Formik
                initialValues={{
                    username: "",
                }}
                validationSchema={loginWithOTPValidator}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, initialTouched, isValid, dirty }) => (
                    <Form>
                        <FormInput
                            label={"Email/Phone Number"}
                            name={"username"}
                            type={"text"}
                        />
                        <Button
                            type="submit"
                            disabled={isSubmitting || !(isValid && dirty)}
                            isLoading={isSubmitting}
                            className="w-full flex !rounded-[3rem] bg-black justify-center px-4 py-2 my-2"
                        >
                            Request Password Reset
                        </Button>
                        {emailSent && (
                            <P className="text-gray-secondary text-xs md:text-xs xl:text-xs mt-1">
                                {emailSent}
                            </P>
                        )}
                        {error && (
                            <P className="text-red mt-1 text-xs md:text-xs xl:text-xs">
                                {error}
                            </P>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ForgotPasswordForm;
