import moment from "moment";
import { ERROR_MSG } from "../constants/common";
import { REF_CODE } from "../constants/routes";

export const getTagsInfo = (plants) => {
    let objCount = {};
    let tagsCount = [];
    let numProjects = 0;
    let totalPower = 0;
    if (plants === undefined) {
        return { tagsCount, numProjects, totalPower };
    }
    plants.forEach((i) => (objCount[i.tag] = (objCount[i.tag] || 0) + 1));
    totalPower = plants.reduce((p, c) => p + parseInt(c.capacity) / 1000, 0);

    for (let i in objCount) {
        const name = i;
        const value = objCount[i];

        tagsCount.push({ name, value });
    }
    numProjects = Object.keys(objCount).length;
    return { tagsCount, numProjects, totalPower };
};

export const getGroupedTags = (plants) => {
    var sorted = {};
    for (var i = 0, max = plants.length; i < max; i++) {
        if (sorted[plants[i].tag] === undefined) {
            sorted[plants[i].tag] = [];
        }
        sorted[plants[i].tag].push(parseInt(plants[i].capacity) / 1000);
    }
    return sorted;
};

export const getSolarValues = (plants, fundRaised, fundLimit) => {
    const overallCapacity =
        plants.length === 0
            ? 0
            : plants.reduce((p, c) => p + parseInt(c.capacity), 0);
    const soldCapacity = (fundRaised / fundLimit) * overallCapacity;

    return { overallCapacity, soldCapacity };
};

export const getInvestorCountAndTerms = (terms) => {
    let fiveYTerm = 0;
    let halfTerm = 0;
    let maxInvestMent = 0;
    let minInvestMent = 0;
    if (terms.length < 2)
        return { fiveYTerm, halfTerm, maxInvestMent, minInvestMent };

    if (terms[0].installments === parseInt(60)) {
        fiveYTerm = terms[0].investorCount;
        halfTerm = terms[1].investorCount;
        maxInvestMent = parseInt(terms[0].installments) / 12;
        minInvestMent = parseInt(terms[1].installments) / 12;
    } else if (terms[0].installments === parseInt(30)) {
        halfTerm = terms[0].investorCount;
        fiveYTerm = terms[1].investorCount;
        maxInvestMent = parseInt(terms[1].installments) / 12;
        minInvestMent = parseInt(terms[0].installments) / 12;
    }

    return {
        fiveYTerm: Number(fiveYTerm),
        halfTerm: Number(halfTerm),
        maxInvestMent,
        minInvestMent,
    };
};

export const getRentalYield = (terms) => {
    let rentalMin = 0;
    let rentalMax = 0;
    if (terms.length < 2) return { rentalMin, rentalMax };

    if (
        parseFloat(terms[0].targetedYieldPercentage) >
        parseFloat(terms[1].targetedYieldPercentage)
    ) {
        rentalMin = parseFloat(terms[1].targetedYieldPercentage).toFixed(2);
        rentalMax = parseFloat(terms[0].targetedYieldPercentage).toFixed(2);
    } else {
        rentalMax = parseFloat(terms[1].targetedYieldPercentage).toFixed(2);
        rentalMin = parseFloat(terms[0].targetedYieldPercentage).toFixed(2);
    }

    return { rentalMax, rentalMin };
};

export const getInternalReturnRate = (terms) => {
    let IRR = 0;
    if (terms.length < 2) return IRR;
    IRR =
        terms[0].targetedIRRPercentage > terms[1].targetedIRRPercentage
            ? terms[0].targetedIRRPercentage
            : terms[1].targetedIRRPercentage;

    return IRR;
};

export const getMinInvestMentTerms = (terms) => {
    let minTerms = {};
    if (terms.length < 2) return minTerms;
    minTerms = terms.find((item) => item.installments === parseInt(30));
    return minTerms;
};

export const getMaxInvestMentTerms = (terms) => {
    let maxTerms = {};
    if (terms.length < 2) return maxTerms;
    maxTerms = terms.find((item) => item.installments === parseInt(60));
    return maxTerms;
};

export const calculateRemainingTime = (startDate, endDate) => {
    if (startDate === 0 || endDate === 0) return "-";

    const startDay = Number(startDate) * 1000;
    const endDay = Number(endDate) * 1000;

    const currentDate = Date.parse(moment(new Date()).format("DD-MMM-YYYY"));

    if (startDay > currentDate) {
        //    "Not yet started"
        const a = moment(startDay);
        const b = moment(currentDate);
        const difference = a.diff(b, "days");
        return {
            message: `Starting in ${difference} ${
                difference > 1 ? "Days" : "Day"
            }`,
            type: "Starting",
            time: difference,
        };
    } else if (currentDate < endDay) {
        //  started already
        const a = moment(endDay);
        const b = moment(currentDate);
        const difference = a.diff(b, "days");

        return {
            message: `${difference}  ${difference > 1 ? "Days" : "Day"} Left`,
            type: "Remaining",
            time: difference,
        };
    } else {
        const a = moment(currentDate);
        const b = moment(endDay);
        const difference = a.diff(b, "days");

        return {
            message: `Ended ${difference}  ${
                difference > 1 ? "Days" : "Day"
            } Ago`,
            type: "Ended",
            time: difference,
        };
    }
};

export const getCurrentStatus = (startDate, endDate, listingStatus) => {
    if (startDate === 0 || endDate === 0 || listingStatus === "") return "-";

    const currentDate = Date.now();

    const startDay = Number(startDate) * 1000;
    const endDay = Number(endDate) * 1000;

    if (currentDate < startDay) {
        return "PENDING";
    } else if (currentDate >= startDay && currentDate <= endDay) {
        return "FUNDING";
    }

    return "PROCURING";
};

export const convertInteger = (value, isInt = true) => {
    let converted;
    if (isInt) {
        converted = parseInt(value.toString().replace(/,/g, ""));
    } else {
        converted = parseFloat(value.toString().replace(/,/g, ""));
    }

    if (isNaN(converted)) {
        return "";
    }
    return converted;
};

export const getCurrencyAmount = (amount) => {
    const oneCr = 10000000;
    const oneLac = 100000;
    const oneTh = 1000;
    const passValue = amount;

    if (passValue < oneLac) {
        const num = passValue / oneTh;
        return `${num % 1 !== 0 ? num.toFixed(2) : num}K`;
    } else if (passValue >= oneLac && passValue < oneCr) {
        const num = passValue / oneLac;
        return `${num % 1 !== 0 ? num.toFixed(2) : num}L`;
    } else {
        const num = passValue / oneCr;
        return `${num % 1 !== 0 ? num.toFixed(2) : num}Cr`;
    }
};
export const getSolarAssetsWithUnit = (amount) => {
    const oneTh = 1000;
    const passValue = amount;
    if (passValue < oneTh) {
        return `${passValue % 1 !== 0 ? passValue.toFixed(2) : passValue}KWp`;
    } else if (passValue >= oneTh) {
        const num = passValue / oneTh;
        return `${num % 1 !== 0 ? num.toFixed(2) : num}MWp`;
    }
};
export const getNameAvatar = (name) => {
    if (name === undefined || name === null) return "";
    else if (name.length < 1) return name;
    const nameArray = name.split(" ").slice(0, 2);
    if (nameArray.length === 1) {
        return nameArray[0].charAt(0).toUpperCase();
    } else {
        return (nameArray[0].charAt(0) + nameArray[1].charAt(0)).toUpperCase();
    }
};

export const isProdEnv = () => {
    if (process.env.REACT_APP_ENV === "production") return true;
    else return false;
};

export const smoothScrollTo = ({ id, offset = 100 }) => {
    let ele = document.getElementById(id);
    window.scrollTo({
        top: ele.offsetTop - 100,
        left: offset,
        behavior: "smooth",
    });
};

export const getURLQueryParams = (location, key, isEncoded = false) => {
    const urlSearchParams = new URLSearchParams(
        isEncoded ? decodeURIComponent(location.search) : location.search
    );
    const params = Object.fromEntries(urlSearchParams.entries());
    const code = params[key] || "";
    return code;
};

export const twoDecimalLocal = (num) => {
    let fraction = "." + `${parseFloat(num).toFixed(2)}`.split(".")[1];
    return `${Number(parseInt(num)).toLocaleString() + fraction}`;
};

export const getRefCode = (state) => {
    if (state !== null && state?.referralCode) return state?.referralCode;

    return REF_CODE;
};

export const numberWithCommas = (number) => {
    return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getPercentage = (total, value) => {
    if (isNaN(total) || isNaN(value) || total < value) return 0.0;

    return Number(((value * 100) / total).toFixed(2));
};

export const getErrorMessage = (err) => {
    return typeof err === "string" ? err : ERROR_MSG;
};

export const validAdultAge = (age) => {
    var ageDifMs = Date.now() - age.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const checkIsAllowed = (userRoles, allowed = []) => {
    return userRoles?.some((role) => allowed.includes(role.name));
};

export const calculateMinMaxPercentage = (input, min, max) => {
    if (input === undefined || min === undefined || max === undefined) return 0;

    return ((input - min) * 100) / (max - min);
};

export const getGreetings = () => {
    const currentHour = moment().format("HH");
    if (currentHour >= 3 && currentHour < 12) {
        return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 15) {
        return "Good Afternoon";
    } else if (currentHour >= 15 && currentHour < 20) {
        return "Good Evening";
    } else if (currentHour >= 20 || currentHour < 3) {
        return "Good Night";
    } else {
        return "Hello";
    }
};
