import React, { useState } from "react";

import { getCurrencyAmount } from "../../../../helpers/helpers";
import cn from "../../../../lib/cn";
import P from "../../../../shared/typography/P";
import Line from "../../../../shared/ui/Line";
import CalculatorRangeSlider from "./CalculatorRangeSlider";
import Overview from "./Overview";
const getTaxable = (terms) => {
    if (!terms) return { taxable: 0, nonTaxable: 0 };
    return terms.reduce(
        (acc, el) => {
            acc.nonTaxable += el?.capitalPayout + el?.profitPayout;
            acc.taxable += el?.remunerationPayout;

            return acc;
        },
        { taxable: 0, nonTaxable: 0 }
    );
};

const Calculator = ({ listing }) => {
    const investmentTerms = listing?.investmentTerms[0];
    const [amount, setAmount] = useState(50000);
    const [inputAmount, setInputAmount] = useState(50000);
    const [amountErr, setAmountErr] = useState(false);
    const handleChangeInvestment = (e) => {
        let value = e.target.value;

        if (
            !isNaN(value) &&
            listing?.listingMaxLimit >= value &&
            Math.floor(listing?.listingMinLimit) <= value
        ) {
            setAmount(value);
            setInputAmount(value);
            setAmountErr(false);
        } else if (
            !isNaN(value) &&
            (listing?.listingMaxLimit < value ||
                listing?.listingMinLimit > value)
        ) {
            setAmountErr(true);
            setInputAmount(value);
        }
    };

    const plant = listing?.plants[0];

    let solarAsset = (
        ((Number(amount) / Number(listing?.fundLimit)) *
            Number(plant?.capacity)) /
        1000
    ).toFixed(2);

    const getOverView = () => {
        return [
            {
                name: "Solar assets owned",
                value: `${solarAsset}kWp`,
            },
            {
                name: "Tenure",
                value: `${investmentTerms?.installments / 12} years`,
            },
            {
                name: "IRR",
                value: `${investmentTerms?.targetedIRRPercentage}%`,
            },
        ];
    };

    let taxAble = getTaxable(investmentTerms?.investmentTermDetailsDisplays);

    let monthlyPayout = Number(
        (amount / 100000) * investmentTerms?.grossPayoutMonthlyPerLakh
    ).toFixed(2);
    const glance = [
        {
            name: "Total payouts",
            value: `${Number(
                monthlyPayout * investmentTerms?.installments
            ).toFixed(2)}`,
        },
        {
            name: "Monthly Payouts",
            value: `${monthlyPayout}`,
        },
        {
            name: "Taxable payouts",
            value: `${Number((taxAble?.taxable * 12 * amount) / 100000).toFixed(
                2
            )}`,
        },
        {
            name: "Non taxable payouts",
            value: `${Number(
                (taxAble?.nonTaxable * 12 * amount) / 100000
            ).toFixed(2)}`,
        },
    ];

    return (
        <div className="lg:flex gap-3 justify-between items-center">
            <div className="flex-1">
                <P className="text-gray lg:hidden">
                    Calculate your payouts & impact
                </P>
                <Overview data={getOverView()} className="py-2.4 lg:hidden" />
                <div className="p-1 6 bg-gray-50 border border-gray-400 rounded-[1.2rem]">
                    <P className="text-gray hidden lg:block md:text-sm xl:text-sm font-semibold lg:mb-3">
                        Calculate your payouts & impact
                    </P>
                    <div className="flex mb-0.8 justify-between lg:mb-4 items-center">
                        <P className="text-gray mb-0.8 md:text-sm xl:text-sm">
                            Enter Investment amount
                        </P>
                        <div className="hidden">
                            <input
                                className={cn(
                                    "py-0.5 px-0.5 hidden lg:block text-sm xl:text-base border-2 rounded-lg overflow-hidden w-full sm:w-[200px] outline-none",
                                    {
                                        "border-red hover:border-red focus:border-red":
                                            amountErr,
                                        "border-primary  hover:border-blue-100 focus:border-blue-100":
                                            !amountErr,
                                    }
                                )}
                                onChange={handleChangeInvestment}
                                value={inputAmount}
                            />
                            <span
                                className={cn(
                                    `text-sm text-red  px-0.4 rounded-s   flex-col z-20 transition-all duration-300`,
                                    {
                                        hidden: !amountErr,
                                        block: amountErr,
                                    }
                                )}
                            >
                                Range is{" "}
                                {getCurrencyAmount(listing?.listingMinLimit)} to{" "}
                                {getCurrencyAmount(listing?.listingMaxLimit)}
                            </span>
                        </div>
                        <P className="text-primary text-center  text-base font-medium font-vietnam  ">
                            ₹ {amount}
                        </P>
                    </div>

                    <CalculatorRangeSlider
                        onChange={(e) => {
                            setAmount(e.target.value);
                            setInputAmount(e.target.value);
                        }}
                        min={10000}
                        max={listing?.fundLimit}
                        value={amount}
                        step={1000}
                    />

                    <div className="flex justify-between ">
                        <P className="text-gray-550 font-vietnam md:text-sm xl:text-sm">
                            {getCurrencyAmount(10000)}
                        </P>
                        <P className="text-gray-550 font-vietnam md:text-sm xl:text-sm">
                            {getCurrencyAmount(listing?.fundLimit)}
                        </P>
                    </div>

                    <Line className={"lg:hidden"} />
                    <P className="text-gray lg:hidden  mb-1.6 font-vietnam">
                        At a glance
                    </P>
                    <div className="grid grid-cols-2 gap-y-2.4 lg:hidden">
                        {glance.map((item, i) => (
                            <div key={i}>
                                <P className="text-gray-550 mb-1.6 text-xs font-vietnam">
                                    {item.name}
                                </P>
                                <P className="text-gray font-medium font-vietnam ">
                                    {item.value}
                                </P>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="hidden lg:flex lg:flex-col lg:w-[50%]">
                <Overview data={getOverView()} className="py-2.4" />
                <Line />
                <div className="grid grid-cols-2 gap-y-2.4">
                    {glance.map((item, i) => (
                        <div key={i}>
                            <P className="text-gray-550 mb-1 text-xs md:text-sm xl:text-sm">
                                {item.name}
                            </P>
                            <P className="text-gray font-medium font-vietnam ">
                                {item.value}
                            </P>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Calculator;
