import { motion } from "framer-motion";
import React, { useState } from "react";
import Accordion from "../../components/accordion/Accordion";
import {
    investFaq,
    investMiscellaneousFaq,
    investRiskFaq,
    kycFaq,
    opporTunityTabfaqs,
    opportunityFaq,
    payoutTaxesFaq,
    propositionFaq,
    referralProgramFaq,
} from "../../constants/faqData";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import {
    slideOpacityRight,
    translateContainer,
    wavyHeadings,
} from "../../shared/animation/constants/landingAnimation";
import SplitText from "../../shared/animation/typography/SplitText";
import P from "../../shared/typography/P";
import SubHeading from "../../shared/typography/SubHeading";
import Tab from "../../shared/ui/tab/Tab";

let allFaqs = [
    ...investFaq,
    ...investMiscellaneousFaq,
    ...investRiskFaq,
    ...kycFaq,
    ...opporTunityTabfaqs,
    ...opportunityFaq,
    ...payoutTaxesFaq,
    ...propositionFaq,
    ...referralProgramFaq,
];

const FaqItem = ({ question, answer, list, format }) => {
    return (
        <Accordion
            labelClass="font-regular font-nunito text-gray  text-sm sm:text-base xl:text-base 2xl:text-xl"
            key={question}
            label={question}
            size="s"
            wrapperClass="bg-white p-0.8 mb-1.6 lg:p-1.6 lg:mb-3.2"
        >
            {answer ? (
                <P
                    className={`${
                        list ? "mb-0.5 " : "mb-0"
                    } text-gray-secondary font-normal text-sm sm:text-base xl:text-base 2xl:text-lg`}
                >
                    {answer}
                </P>
            ) : null}
            {format ? (
                <P
                    dangerouslySetInnerHTML={format}
                    className=" text-gray-secondary   text-sm sm:text-base xl:text-base 2xl:text-lg"
                    style={{
                        marginBottom: list ? "5px" : "0px",
                        marginTop: answer ? "5px" : "0px",
                    }}
                ></P>
            ) : null}
            {list
                ? list.map((item, index) => (
                      <div
                          className="flex mb-0.5"
                          key={index}
                          style={{ marginBottom: "5px" }}
                      >
                          <div>
                              <P className="w-[15px] mr-1 text-gray-secondary   text-sm sm:text-base xl:text-base 2xl:text-lg">
                                  {`${index + 1}.`}
                              </P>
                          </div>
                          <div className="flex-1">
                              <P className="text-gray-secondary   text-sm sm:text-base xl:text-base 2xl:text-lg">
                                  {item}
                              </P>
                          </div>
                      </div>
                  ))
                : null}
        </Accordion>
    );
};
const Faq = () => {
    const [active, setActive] = useState(filteredItem[0]);
    const [displayFaq, setDisplayFaq] = useState(investFaq);

    const renderFaq = (data, value) => {
        return displayFaq.map(
            (item, i) =>
                i % 2 === value && (
                    <FaqItem
                        key={item?.question}
                        list={item?.list}
                        question={item?.question}
                        answer={item?.answer}
                        format={item?.format}
                    />
                )
        );
    };
    const handleFilterFaq = (data) => {
        setActive(data);
        let newFaqs = allFaqs.filter((item) => item?.tags?.includes(data.name));
        setDisplayFaq(newFaqs.slice(0, 10));
    };
    return (
        <Section className="bg-background py-4 md:py-6  xl:py-9.6 mb-0 xl:mb-0 lg:mb-0 2xl:mb-0">
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className=" lg:mb-5 xl:mb-6.4 overflow-x-hidden"
                >
                    <div className="w-full  ">
                        <SplitText heading="h2" variants={wavyHeadings}>
                            Still looking, Explore our FAQs section
                        </SplitText>
                    </div>
                    <motion.div variants={slideOpacityRight}>
                        <SubHeading className="">
                            We have organised most commonly asked questions in
                            different sections.
                        </SubHeading>
                    </motion.div>
                </motion.div>

                <div className="flex flex-nowrap overflow-x-auto  xl:w-full gap-2.4 no-scrollbar mb-2.4 xl:mb-5.6 mt-2.4 lg:mt-0">
                    {filteredItem.map((item, i) => (
                        <Tab
                            active={item.name === active.name}
                            key={item.id}
                            text={item.name}
                            onClick={() => handleFilterFaq(item)}
                        />
                    ))}
                </div>

                <div className="lg:flex justify-between gap-3.2">
                    <div className="flex-1">{renderFaq(investFaq, 0)}</div>
                    <div className="flex-1">{renderFaq(investFaq, 1)}</div>
                </div>
            </Container>
        </Section>
    );
};

export default Faq;

const filteredItem = [
    {
        id: 1,
        name: "Trending",
    },
    {
        id: 2,
        name: "Invest",
    },

    {
        id: 3,
        name: "Technology",
    },
    {
        id: 4,
        name: "Government Subsidy",
    },
    {
        id: 5,
        name: "Co2 Reduced",
    },
    {
        id: 6,
        name: "Srot",
    },
    {
        id: 8,
        name: "Solar",
    },
];
