import moment from "moment";
import React from "react";
import cn from "../../../../lib/cn";
import { locationMarker } from "../../../../resources/js/icons";
import P from "../../../../shared/typography/P";

// Milestones:
//     - Pre-launch : current_date<startDate
//     - Funding: current_date>=startDate && current_date<=endDate
//     - Installation:   endDate <   current_date < activationDate
//     - Active: current_date >= activationDate
const getActiveProgress = (listing) => {
    const current_date = moment().unix().valueOf();
    let activationDate =
        listing?.activationDate ||
        moment.unix(listing.endDate).add(2, "months").unix();
    let startDate = listing?.startDate;
    let endDate = listing?.endDate;

    if (activationDate && current_date >= activationDate) return 3;
    if (
        activationDate &&
        endDate &&
        endDate < current_date &&
        current_date < activationDate
    )
        return 2;
    if (
        endDate &&
        startDate &&
        current_date >= startDate &&
        current_date <= endDate
    )
        return 1;

    return 0;
};
const progress = [
    {
        id: 1,
        name: "Pre-launch",
    },
    {
        id: 2,
        name: "Funding",
    },
    {
        id: 3,
        name: "Installation",
    },
    {
        id: 4,
        name: "Active",
    },
];
const ProjectProgress = ({ listing }) => {
    return (
        <div className="relative">
            <div className="w-full h-[5px] rounded-full bg-linear-button relative mb-5">
                <div className="absolute inset-x-0 inset-y-0 flex items-center justify-between rounded-full">
                    {progress.map((item, i) => (
                        <div
                            key={item.id}
                            className={cn("h-1 w-1  ", {
                                "bg-white border-2 rounded-full border-primary":
                                    i <= getActiveProgress(listing),
                            })}
                        >
                            <div className="relative">
                                {i === getActiveProgress(listing) && (
                                    <div className="absolute bottom-0 -right-0.8">
                                        <div className="h-4 w-2  flex items-end ">
                                            <img
                                                src={locationMarker}
                                                alt="locationMarker"
                                                className="w-full inline-block object-cover"
                                            />
                                        </div>
                                    </div>
                                )}
                                <P
                                    className={cn(
                                        "absolute -bottom-3 text-xs text-nowrap xl:text-xs 2xl:text-sm",
                                        {
                                            "left-0": i < 1,
                                            "-left-1.5":
                                                i > 0 &&
                                                i < progress.length - 1,

                                            "right-0":
                                                i === progress.length - 1,
                                        }
                                    )}
                                >
                                    {item.name}
                                </P>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProjectProgress;
