import React, { useState } from "react";

import moment from "moment";
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useParams,
    useRouteMatch,
} from "react-router-dom";
import {
    OPPORTUNITY_DETAILS_ENDPOINT,
    PORTFOLIO,
    PORTFOLIO_INVESTMENTS_ENDPOINT,
} from "../../../../constants/routes";
import useNetwork from "../../../../hooks/useNetwork";
import Container from "../../../../shared/Container";
import Section from "../../../../shared/Section";
import P from "../../../../shared/typography/P";
import MyInvestment from "../../../home/ExistingUser/ExistingUserMobile/MyInvestment";
import Calculator from "../../../project/mobile/project-details/Calculator";
import Documents from "../../../project/mobile/project-details/Documents";
import Faq from "../../../project/mobile/project-details/Faq";
import Summary from "../../../project/mobile/project-details/Summary";
import DetailsHead from "./DetailsHead";
import DetailsInvestments from "./DetailsInvestments";
import DetailsPayout from "./DetailsPayouts";
import { getPayoutData } from "./InvestmentDetails";
const detailsTabs = ["Summary", "Documents", "Calculator", "FAQS"];

/* @TODO => Verify fonts for all the components @habib610 Thu September 26,2024 */
const PortfolioDetails = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const { id } = useParams();
    const [listingId] = useState(id);
    const history = useHistory();
    const [body] = useState({
        id: 1,
    });
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        history.push(`${url}/${tab.toLowerCase()}`);
    };
    const { data: investmentData } = useNetwork(PORTFOLIO_INVESTMENTS_ENDPOINT);

    const { path, url } = useRouteMatch();
    const [activeTab, setActiveTab] = useState(detailsTabs[0]);
    const { data: investorPortfolioData } = useNetwork(
        `/investment/${listingId}`
    );
    const { data: assetDetails } = useNetwork(
        OPPORTUNITY_DETAILS_ENDPOINT,
        "POST",
        body
    );

    if (!assetDetails?.data?.listing) return;
    const listing = assetDetails?.data?.listing;

    const investmentTerms = listing?.investmentTerms[0];
    const plant = listing?.plants[0];

    const investment = investorPortfolioData?.data?.investment;
    let startDate = listing?.activationDate
        ? listing?.activationDate * 1000
        : moment(investment?.investment_date * 1000)
              .add(2, "M")
              .valueOf();
    const payoutData = getPayoutData(investment, listing, investmentTerms);

    return (
        <Section className="bg-black-600 h-full  xl:m-0  lg:bg-white pt-10.4 m-0 ">
            <Container className="px-0 max-w-full xl:container lg:px-2 lg:flex lg:flex-row gap-1 items-start">
                <div className="lg:w-[60%] xl:w-[70%]">
                    <Container>
                        <DetailsHead
                            name={plant?.name}
                            plant={plant}
                            status={investment?.investment_status}
                            date={investment?.investment_date}
                            totalPayout={
                                investment?.monthly_gross_payout *
                                (investment?.pending_installments +
                                    investment?.paid_installments)
                            }
                            investment={investment}
                            investmentAmount={investment?.investment_amount}
                            digioStatus={investment?.digioStatus}
                            investmentId={investment?.investment_id}
                            currentTab={currentTab}
                        />
                    </Container>
                    <div className="bg-white rounded-tr-2 rounded-tl-2 py-2.4 ">
                        <Container className="lg:hidden">
                            <DetailsInvestments
                                currentTab={currentTab}
                                setCurrentTab={setCurrentTab}
                                investment={investment}
                                listing={listing}
                                investmentTerms={investmentTerms}
                            />
                        </Container>
                        <Container className="hidden lg:block  xl:mb-3 h-full">
                            <div className="border border-gray-400 rounded-xl p-3  ">
                                <div className="border-b border-b-blue-50 pb-1">
                                    <P>
                                        <span className="border-b-2 border-b-blue pb-1 ">
                                            Payouts on 3rd of every month
                                        </span>
                                    </P>
                                </div>

                                <div className="flex flex-col items-stretch gap-2 xl:items-center xl:flex-row xl:justify-between my-4  ">
                                    {payoutData
                                        .reduce((result, payout, index) => {
                                            const groupIndex = Math.floor(
                                                index / 3
                                            );
                                            if (!result[groupIndex]) {
                                                result[groupIndex] = [];
                                            }
                                            result[groupIndex].push(
                                                <div key={payout.id}>
                                                    <p className="text-xs text-gray-550  text-nowrap mb-1.6 font-vietnam">
                                                        {payout.name}
                                                    </p>
                                                    <p className="text-sm text-nowrap text-gray font-vietnam">
                                                        {payout.value}
                                                    </p>
                                                </div>
                                            );
                                            return result;
                                        }, [])
                                        .map((group, idx) => (
                                            <div
                                                key={idx}
                                                className="flex justify-between gap-x-2 bg-gray-70 p-1 rounded-2xl flex-row gap-y-2.4 w-full  xl:w-6/12 2xl:w-5/12"
                                            >
                                                {group}
                                            </div>
                                        ))}
                                </div>
                                <Container className="border border-blue-50 pb-3 rounded-2xl ">
                                    <div className="lg:flex lg:justify-between lg:items-center lg:border-b lg:border-b-blue-50 lg:mt-2  lg:mb-2.4">
                                        <div className="flex  justify-between lg:justify-start  lg:gap-2 border-b border-b-blue-50 lg:border-none mb-2.4 lg:mb-0 ">
                                            {detailsTabs.map((tab, i) => (
                                                <div
                                                    key={tab}
                                                    onClick={() =>
                                                        handleTabChange(tab)
                                                    }
                                                    className={`flex items-center   gap-1   ${
                                                        activeTab === tab
                                                            ? "text-blue-500  "
                                                            : "text-gray-500"
                                                    }`}
                                                >
                                                    <P
                                                        className={` font-vietnam border-b-2 md:text-sm xl:text-sm pb-1 lg:pb-3  ${
                                                            activeTab === tab
                                                                ? "text-blue-500  border-b-blue-500 "
                                                                : "text-gray-500 border-b-transparent"
                                                        }`}
                                                    >
                                                        {tab}
                                                    </P>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <Switch>
                                        <Route
                                            path={`${PORTFOLIO}/${id}/summary`}
                                            component={() => (
                                                <Summary
                                                    listing={listing}
                                                    isScrollDisabled={
                                                        document.body
                                                            .clientWidth > 1024
                                                    }
                                                    buttonHidden={true}
                                                    projectHidden={true}
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${path}/documents`}
                                            component={() => (
                                                <Documents
                                                    documents={
                                                        listing?.documents
                                                    }
                                                    images={listing?.images}
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${path}/calculator`}
                                            component={() => (
                                                <Calculator listing={listing} />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path={`${path}/faqs`}
                                            component={Faq}
                                        />
                                        <Route
                                            exact
                                            path={`${PORTFOLIO}/${id}`}
                                        >
                                            <Redirect
                                                to={`${PORTFOLIO}/${id}/summary`}
                                            />
                                        </Route>
                                    </Switch>
                                </Container>
                            </div>
                        </Container>
                    </div>
                </div>

                <div className="hidden lg:block lg:w-[35%] xl:w-[28%]">
                    <DetailsPayout
                        payoutId={id}
                        investments={
                            investmentData?.data?.portfolio?.investments
                        }
                    />
                    <div className="mt-3">
                        <MyInvestment investmentData={investmentData} />
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default PortfolioDetails;
