import React, { useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

import cn from "../../lib/cn";
import Container from "../../shared/Container";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import LabeledInput from "../../shared/ui/Form/LabeledInput";
const validateForm = (formData, getOtp) => {
    const newErrors = {};

    if (!formData.current_password.trim()) {
        newErrors.current_password = "Old Password is required";
    }

    if (!formData.new_password.trim()) {
        newErrors.new_password = "New Password is required";
    }
    if (!formData.confirmPass.trim()) {
        newErrors.confirmPass = "Password is required";
    } else if (formData.confirmPass !== formData.new_password) {
        newErrors.confirmPass = "New Password does not match";
    }

    return newErrors;
};

const ResetPassword = ({ onClick, className }) => {
    const [showOTP, setShowOTP] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        current_password: "",
        new_password: "",
        confirmPass: "",
    });
    const Submit = () => {
        const body = {
            current_password: formData?.current_password,
            new_password: formData?.new_password,
        };
        onClick(body);
    };
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };
    return (
        <div
            className={cn(
                `w-full   bg-white rounded-t-2 pb-6 overflow-y-auto   h-[calc(100vh-40vh)]`,
                className
            )}
        >
            <Container className="">
                <P className="text-gray mb-2 mt-2.4 font-vietnam">
                    Reset password
                </P>
                <LabeledInput
                    label="Old password"
                    name="current_password"
                    placeholder="Enter password"
                    iconChange={() => setShowOldPassword((prev) => !prev)}
                    type={showOldPassword ? "text" : "password"}
                    icon={
                        showOldPassword ? <IoEyeOutline /> : <IoEyeOffOutline />
                    }
                    onChange={handleChange}
                />
                <LabeledInput
                    label="Create new password"
                    placeholder="Enter password"
                    name="new_password"
                    iconChange={() => setShowPassword((prev) => !prev)}
                    type={showPassword ? "text" : "password"}
                    icon={showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    onChange={handleChange}
                />
                <LabeledInput
                    label="Re enter new password"
                    placeholder="Re enter password"
                    name="confirmPass"
                    iconChange={() => setShowConfirmPassword((prev) => !prev)}
                    type={showConfirmPassword ? "text" : "password"}
                    onChange={handleChange}
                    icon={
                        showConfirmPassword ? (
                            <IoEyeOutline />
                        ) : (
                            <IoEyeOffOutline />
                        )
                    }
                />
                {/* {showOTP && (
                    <div className="mb-2.4">
                        <P className="text-gray font-medium font-vietnam mb-0.8 ">
                            Verify OTP
                        </P>
                        <P className="text-gray font-vietnam mb-2.4">
                            Enter the OTP received on your mobile number
                        </P>

                        <OTP label={null} />
                    </div>
                )} */}

                <Button
                    onClick={Submit}
                    className="bg-gray text-white w-full rounded-full font-vietnam"
                >
                    Continue
                </Button>
            </Container>
        </div>
    );
};

export default ResetPassword;
