import moment from "moment";
import React, { useState } from "react";
import { getCurrencyAmount } from "../../../../helpers/helpers";
import { funding } from "../../../../resources/js/icons";
import P from "../../../../shared/typography/P";
import ProjectProgress from "../../../project/mobile/project-details/ProjectProgress";

const getPayoutData = (data) => {
    return [
        {
            id: 1,
            name: "Carbon impact",
            value: "243 tons" /* @TODO => fix carbonImpact @habib610 Mon December 09,2024 */,
        },
        {
            id: 2,
            name: "Tenure",
            value: `${data?.installments / 12} Years`,
        },
        {
            id: 3,
            name: "IRR",
            value: `${data?.targetedIRRPercentage}%`,
        },
    ];
};

const ProjectDetails = (props) => {
    const listing = props?.listing;
    const investmentTerms = listing.investmentTerms[0];

    const [activeTab, setActiveTab] = useState(0);

    return (
        <div>
            <P className="text-gray mb-1.6">Description</P>
            <P>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Necessitatibus, obcaecati praesentium perspiciatis molestias
                consequuntur eaque maiores enim repellendus debitis aspernatur.
            </P>

            <div className="grid grid-cols-3 gap-x-3.2 gap-y-2.4 mb-4 bg-white mt-2.4 ">
                {getPayoutData(investmentTerms).map((payout) => (
                    <div key={payout.id}>
                        <P className="text-xs text-gray-550 mb-1.6 font-vietnam">
                            {payout.name}
                        </P>
                        <P className="text-sm text-gray font-vietnam">
                            {payout.value}
                        </P>
                    </div>
                ))}
            </div>

            <div className="p-2.4 rounded-2 border border-gray-400">
                <div className="flex mb-2 gap-2 border-b border-b-blue-50">
                    {["Milestone", "Funding"].map((tab, i) => (
                        <div
                            key={tab}
                            onClick={() => setActiveTab(i)}
                            className={`flex items-center border-b-2 gap-1 pb-1 font-vietnam ${
                                activeTab === i
                                    ? "text-blue-500  border-b-blue-500"
                                    : "text-gray-500 border-b-transparent"
                            }`}
                        >
                            <P
                                className={` ${
                                    activeTab === i
                                        ? "text-blue-500 "
                                        : "text-gray-500"
                                }`}
                            >
                                {tab}
                            </P>
                        </div>
                    ))}
                </div>
                {activeTab === 0 ? (
                    <>
                        <ProjectProgress listing={listing} />
                        <div className="w-full h-px bg-gray-400 mb-2.4"></div>
                        <div className="grid grid-cols-2">
                            <div>
                                <P className="text-xs text-gray-550 mb-1.6 font-vietnam">
                                    Funding Started
                                </P>
                                <P className="text-sm text-gray font-vietnam">
                                    {moment(listing?.startDate * 1000).format(
                                        "Do MMMM yyyy"
                                    )}
                                </P>
                            </div>
                            <div>
                                <P className="text-xs text-gray-550 mb-1.6 font-vietnam">
                                    Funding End
                                </P>
                                <P className="text-sm text-gray font-vietnam">
                                    {moment(listing?.endDate * 1000).format(
                                        "Do MMMM yyyy"
                                    )}
                                </P>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex  items-center  justify-center  rounded-2 mb-1.6 gap-1 bg-green-50 px-2 py-1">
                            <img src={funding} alt="funding" />
                            <P>
                                {listing?.listingStatus !== "FUNDING"
                                    ? "Funding plans are completed"
                                    : "Funding plans are on progress"}{" "}
                            </P>
                        </div>

                        <div className="flex justify-between">
                            <div>
                                <P className="text-gray-550">Capital raised</P>
                                <P className="text-gray mt-0.8 font-medium font-vietnam">
                                    ₹ {getCurrencyAmount(listing?.fundRaised)}
                                </P>
                            </div>{" "}
                            <div>
                                <P className="text-gray-550">Total investors</P>
                                <P className="text-gray mt-0.8 text-end font-medium font-vietnam">
                                    {investmentTerms?.investorCount}
                                </P>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProjectDetails;
