import React, { useState } from "react";

import ModalAlt from "../../../components/modal/ModalAlt";
import {
    PORTFOLIO_INVESTMENTS_ENDPOINT,
    PORTFOLIO_TRANSACTION_ENDPOINT,
} from "../../../constants/routes";
import useNetwork from "../../../hooks/useNetwork";
import Portfolio from "../mobile";
import DetailsPayout from "../mobile/portfolio-details/DetailsPayouts";
import Transaction from "../mobile/portfolio-details/transactions";
import FilterTransactions from "./LeftSide/FilterTransactions";

const PortfolioDash = () => {
    const [params, setParams] = useState("");
    const [transactionFilter, setTransactionFilter] = useState(false);
    const {
        data: investmentData,
        loading: portfolioLoading,
        error: portfolioError,
    } = useNetwork(PORTFOLIO_INVESTMENTS_ENDPOINT);

    const { data: transactionData } = useNetwork(
        PORTFOLIO_TRANSACTION_ENDPOINT + params
    );
    const [activeTab, setActiveTab] = useState(0);
    const onApplyFilter = (filter) => {
        if (filter !== params) {
            setParams(filter);
        }
        setTransactionFilter(false);
    };
    const handleActiveTab = (tab) => {
        setActiveTab(tab);
    };
    const transactions = transactionData?.data?.transactions;

    return (
        <div className="lg:px-4 2xl:container">
            <div className="mt-5 lg:pt-5 bg-black-600 lg:bg-white lg:flex justify-between items-start">
                <div className=" w-full lg:w-[75%]">
                    <Portfolio
                        activeTab={activeTab}
                        handleActiveTab={handleActiveTab}
                        onClick={() => setTransactionFilter(true)}
                        investmentData={investmentData}
                        transactionData={transactionData}
                        portfolioLoading={portfolioLoading}
                        portfolioError={portfolioError}
                    />
                </div>
                <div className="hidden lg:block lg:w-[35%] ">
                    <div className=" top-5 lg:top-5  h-full">
                        <div className="mx-2 h-full">
                            {activeTab === 0 ? (
                                <Transaction
                                    isScrollDisabled={false}
                                    onClick={() => setTransactionFilter(true)}
                                    transactions={transactions}
                                    investedCapital={
                                        investmentData?.data?.portfolio
                                            ?.investedCapital
                                    }
                                />
                            ) : (
                                <div className=" mt-3">
                                    <DetailsPayout
                                        investments={
                                            investmentData?.data?.portfolio
                                                ?.investments
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ModalAlt
                isOpen={transactionFilter}
                onClose={() => setTransactionFilter(false)}
                times={false}
                topPadding="20px"
            >
                <FilterTransactions onApply={onApplyFilter} />
            </ModalAlt>
        </div>
    );
};

export default PortfolioDash;
