import React, { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";

import moment from "moment";
import P from "../../../../shared/typography/P";
import Button from "../../../../shared/ui/Button";
import Line from "../../../../shared/ui/Line";
import MultiRangeSlider from "./RangeSlider";

const dateRanges = [
    {
        name: "Last 30 days",
        value: moment().subtract(30, "days").valueOf() / 1000,
        id: 3,
    },
    {
        name: "Last 90 days",
        value: moment().subtract(90, "days").valueOf() / 1000,
        id: 4,
    },
    {
        name: "Last 6 months",
        value: moment().subtract(6, "months").valueOf() / 1000,
        id: 5,
    },
    {
        name: "Last 1 year",
        value: moment().subtract(1, "y").valueOf() / 1000,
        id: 6,
    },
    {
        name: "Last 5 years",
        value: moment().subtract(5, "years").valueOf() / 1000,
        id: 7,
    },
];

let min = 100;
let max = 500000;
const FilterTransactions = ({ onApply }) => {
    const [dateRange, setDateRange] = useState({});
    const [status, setStatus] = useState("");
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);

    const handleClearAll = () => {
        setDateRange({});
        setStatus();
        onApply("");
    };

    const handleDateRangeChange = (range) => {
        if (range?.id === dateRange?.id) {
            setDateRange("");
        } else {
            setDateRange(range);
        }
    };
    const handleStatusChange = (newStatus) => {
        if (status === newStatus) {
            setStatus("");
        } else {
            setStatus(newStatus);
        }
    };

    // const handleFileTypeChange = (type) => {
    //     setFileType(type);
    // };

    const handleApply = () => {
        // dateRangeStartDate
        // dateRangeEndDate
        // transactionType
        // minAmount
        // maxAmount

        let str = `?minAmount=${minVal}&maxAmount=${maxVal}`;
        if (status) str += `&transactionType=${status}`;
        if (dateRange?.value)
            str += `&dateRangeStartDate=${Math.round(dateRange?.value)}`;

        onApply(str);
    };

    return (
        <div className="p-1 lg:p-2  bg-white rounded-lg font-vietnam ">
            <h3 className="text-base font-semibold mb-4">
                Filter Transactions
            </h3>

            <div className="mb-1 lg:mb-2 ">
                <P className="font-medium xl:text-base text-gray-secondary font-vietnam">
                    Date Range
                </P>
                <div className="flex  gap-x-1 gap-y-2 mt-2 overflow-x-auto no-scrollbar">
                    {dateRanges.map((range, i) => (
                        <div
                            role="button"
                            key={i}
                            className={`px-1 py-0.5 rounded-3xl font-vietnam ${
                                dateRange?.id === range?.id
                                    ? "bg-gray-150 text-blue"
                                    : "border border-gray-150 text-gray-500"
                            }`}
                            onClick={() => handleDateRangeChange(range)}
                        >
                            <p className="text-nowrap flex items-center gap-1 text-sm">
                                {range.name}
                                <P className="text-blue font-vietnam">
                                    {dateRange?.id === range?.id && (
                                        <MdOutlineCancel />
                                    )}
                                </P>
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <Line className={"lg:hidden"} />
            <div className="flex flex-wrap lg:flex-nowrap justify-between gap-x-2 gap-y-2">
                <div className="mb-1 lg:mb-2">
                    <P className="font-medium xl:text-base text-gray-secondary font-vietnam">
                        Status
                    </P>
                    <div className="flex space-x-1 mt-1 lg:mt-2">
                        {["DEBIT", "CREDIT"].map((s) => (
                            <div
                                role="button"
                                key={s}
                                className={`px-1 py-0.5 rounded-3xl font-vietnam ${
                                    status === s
                                        ? "bg-gray-150 text-blue"
                                        : "border border-gray-150 text-gray-500"
                                }`}
                                onClick={() => handleStatusChange(s)}
                            >
                                <p className="text-nowrap flex items-center gap-1 text-sm">
                                    {s}
                                    <P className="text-blue">
                                        {status === s && <MdOutlineCancel />}
                                    </P>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Line className={"lg:hidden"} />
            <div className="flex flex-wrap-reverse xl:flex-nowrap justify-between items-start gap-1">
                <Line className={"w-full lg:hidden"} />
                <div className="w-full md:w-2/3 xl:w-4/6 2xl:w-3/6">
                    <P className="font-medium xl:text-base text-gray-secondary mb-2 font-vietnam">
                        Amount{" "}
                        <span className="font-normal ml-2 ">
                            ₹ {minVal} - {maxVal}
                        </span>
                    </P>
                    <MultiRangeSlider
                        min={min}
                        max={max}
                        minVal={minVal}
                        maxVal={maxVal}
                        setMaxVal={setMaxVal}
                        setMinVal={setMinVal}
                    />
                </div>
            </div>

            <div className="flex mt-3 gap-3">
                <Button
                    onClick={handleClearAll}
                    role="button"
                    className="bg-white !border !border-black text-base text-black py-1 px-2 rounded-[3rem] font-vietnam"
                >
                    Clear All
                </Button>
                <Button
                    onClick={handleApply}
                    className="bg-black !rounded-[3rem] !px-2 !py-1"
                >
                    Apply
                </Button>
            </div>
        </div>
    );
};

export default FilterTransactions;
