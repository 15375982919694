import React from "react";
import MyInvestment from "../../../home/ExistingUser/ExistingUserMobile/MyInvestment";

const RightPart = ({ investmentData }) => {
    return (
        <div className="mx-2">
            <MyInvestment investmentData={investmentData} />
        </div>
    );
};

export default RightPart;
