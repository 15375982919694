import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import AboutUs from "./app/about-us";
import BlogDetails from "./app/blog-details";
import ContactUS from "./app/contact-us";
import CutomerSupport from "./app/customerSupport/index.js";
import Home from "./app/home";
import ExistingUserDashBoard from "./app/home/ExistingUser";
import NewUser from "./app/home/ExistingUser/NewUser";
import Impact from "./app/impact/index.js";
import KYC from "./app/kyc/index.js";
import PortfolioDash from "./app/portfoilo/desktop/index.js";
import PortfolioDetails from "./app/portfoilo/mobile/portfolio-details/index.js";
import Profile from "./app/profile";
import Projects from "./app/project/index.js";
import InvestProcess from "./app/project/mobile/invest/InvestProcess.js";
import ProjectDetails from "./app/project/ProjectDetails/index.js";
import RewardsDashBoard from "./app/rewards/index.js";
import Referral from "./app/rewards/mobile/Referral.js";
import SignUp from "./app/signup/index.js";
import TermsAndConditions from "./app/terms-and-condition";
import ErrorFallback from "./components/error/ErrorFallback";
import LoadingSpinner from "./components/loading/LoadingSpinner";

import {
    ABOUTUS,
    BLOG,
    CONTACTUS,
    CUSTOMERSUPPORT,
    ERROR_SCREEN,
    FAQ,
    FORGET,
    HOME,
    IMPACT,
    INVEST,
    LOGIN,
    NOT_FOUND,
    PORTFOLIO,
    PROFILE,
    PROJECTS,
    REFERRAL,
    RESET_SCREEN,
    REWARDS,
    SIGNUP,
    TERMS_N_CONDITIONS,
    USER_HOME,
    VERIFY_EMAIL,
} from "./constants/routes";

import Login from "./app/login/index.js";
import ResetPassword from "./app/login/ResetPassword.js";
import { useContextState } from "./context/ContextProvider";
import FrequentQuestion from "./faq/FrequentQuestion";
import useAuth from "./hooks/useAuth";
import NotFound from "./notfound";
import ResetScreen from "./reset/ResetScreen";
import PrivateRoute from "./routes/PrivateRoute.js";
import PublicRoute from "./routes/PublicRoute.js";
import auth from "./services/auth";
import VerifyEmail from "./verify-email";

const routeConfigs = [
    {
        path: HOME,
        RenderComponent: Home,
        isAuthReq: false,
        exact: true,
    },
    {
        path: USER_HOME,
        RenderComponent: ExistingUserDashBoard,
        isAuthReq: true,
        exact: true,
    },

    {
        path: ABOUTUS,
        RenderComponent: AboutUs,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `${BLOG}/:blogId`,
        RenderComponent: BlogDetails,
        isGlobal: true,
        isAuthReq: false,
    },
    {
        path: FAQ,
        RenderComponent: FrequentQuestion,
        isAuthReq: false,
    },
    {
        path: TERMS_N_CONDITIONS,
        RenderComponent: TermsAndConditions,
        isAuthReq: false,
    },
    {
        path: LOGIN,
        RenderComponent: Login,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `${SIGNUP}`,
        RenderComponent: SignUp,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `${FORGET}`,
        RenderComponent: ResetPassword,
        isAuthReq: false,
        exact: true,
    },

    {
        path: IMPACT,
        RenderComponent: Impact,
        isAuthReq: false,
        exact: true,
    },
    {
        path: CONTACTUS,
        RenderComponent: ContactUS,
        isAuthReq: false,
    },
    {
        path: PROJECTS,
        RenderComponent: Projects,
        isAuthReq: true, // change
        // isGlobal: true,
        exact: true,
    },
    {
        path: `${PROJECTS}/:id`,
        RenderComponent: ProjectDetails,
        isAuthReq: true, //change
        exact: false,
    },
    {
        path: CUSTOMERSUPPORT,
        RenderComponent: CutomerSupport,
        isAuthReq: true, // change
        exact: true,
        isGlobal: true,
    },
    {
        path: `${INVEST}/:listingId`,
        RenderComponent: InvestProcess,
        isAuthReq: true, //change
        exact: true,
    },
    {
        path: REWARDS,
        RenderComponent: RewardsDashBoard,
        isAuthReq: true, //change
        exact: true,
    },
    {
        path: `${REWARDS}${REFERRAL}`,
        RenderComponent: Referral,
        isAuthReq: false, //change
        exact: true,
    },
    {
        path: "/kyc/summary",
        RenderComponent: KYC,
        isAuthReq: true, //change
        isGlobal: true,
        exact: false,
    },

    {
        path: PORTFOLIO,
        RenderComponent: PortfolioDash,
        isAuthReq: true, // change
        exact: true,
    },
    {
        path: `${PORTFOLIO}/:id`,
        RenderComponent: PortfolioDetails,
        isAuthReq: true, // change
        exact: false,
    },
    {
        path: "/newUser",
        RenderComponent: NewUser,
        isAuthReq: false, // change
        exact: true,
    },

    {
        path: PROFILE,
        RenderComponent: Profile,
        isAuthReq: true,
        exact: true,
    },
    {
        path: VERIFY_EMAIL,
        RenderComponent: VerifyEmail,
        isAuthReq: false,
    },

    {
        path: RESET_SCREEN, // TODO  : check if we need to redirect ?
        RenderComponent: ResetScreen,
        isAuthReq: false,
    },

    {
        path: ERROR_SCREEN,
        RenderComponent: ErrorFallback,
        isAuthReq: false,
    },
    {
        path: NOT_FOUND,
        RenderComponent: NotFound,
        isAuthReq: false,
    },
];

const RouteComponent = ({
    RenderComponent,
    isAuthReq,
    redirectIfAuth,
    isGlobal,
    ...restProps
}) => {
    const { state, dispatch } = useContextState();
    const { isAuth } = useAuth(restProps?.location?.pathname, dispatch);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        // Fetch user state on reload on a page when user is loggedin
        if (!state.user && isAuth) {
            auth.getUserProfile(dispatch);
        }
    }, [dispatch, state.user, isAuth]);

    useEffect(() => {
        if (isAuth !== null && !showContent) {
            setTimeout(() => {
                setShowContent(true);
            }, 5000);
        }
    }, [showContent, isAuth]);

    if (isAuth === null) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }
    /* @TODO => Commenting intro screen Wed May 29,2024 */

    // if (isAuth !== null && !showContent) {
    //     return (
    //         <div>
    //             <IntroScreen />
    //         </div>
    //     );
    // }

    return (
        <AnimatePresence>
            {isAuthReq ? (
                <PrivateRoute component={RenderComponent} {...restProps} />
            ) : isGlobal ? (
                <RenderComponent {...restProps} />
            ) : (
                <PublicRoute component={RenderComponent} {...restProps} />
            )}
        </AnimatePresence>
    );
};

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const Routes = () => {
    return (
        <>
            <ScrollToTop />
            <Switch>
                {routeConfigs.map((routeConfig) => (
                    <RouteComponent {...routeConfig} />
                ))}
                <Redirect to={NOT_FOUND} />
            </Switch>
        </>
    );
};

export default Routes;
