import React from "react";
import styled from "styled-components";
import Flex from "../components/flex/Flex";
import Text from "../components/text/Text";
import { smoothScrollTo } from "../helpers/helpers";

export const HeaderHeight = {
    default: "75px",
    smallLaptop: "70px",
    tablet: "60px",
};
const Container = styled.div`
    position: sticky;
    height: calc(100vh - ${HeaderHeight.default});
    top: ${HeaderHeight.default};
    display: flex;
    align-items: center;
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        height: calc(100vh - ${HeaderHeight.smallLaptop});
        top: ${HeaderHeight.smallLaptop};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        height: calc(100vh - ${HeaderHeight.tablet});
        top: ${HeaderHeight.tablet};
    }
`;
const ScrollerNavContainer = styled.div`
    margin-left: 40px;
`;
const BallContainer = styled.div`
    margin-left: 12px;
    border-left: solid 1px
        ${(props) =>
            props.isActive
                ? props.theme.color.primary
                : props.theme.color.lightGrey};
    position: relative;
`;
const OutterBall = styled.div`
    border: solid 1px
        ${(props) =>
            props.isActive
                ? props.theme.color.primary
                : props.theme.color.lightGrey};
    position: absolute;
    left: -6px;
    top: 18px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: all ease-in-out 0.5s;
    transform: ${(props) => (props.isActive ? "scale(2.5)" : "")};
`;
const Ball = styled.div`
    background: ${(props) =>
        props.isActive
            ? props.theme.color.primary
            : props.theme.color.lightGrey};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: -6px;
    top: 18px;
    transition: all ease-in-out 0.5s;
    transform: ${(props) => (props.isActive ? "scale(1.4)" : "")};
`;
const NavText = styled(Text)`
    padding: 15px 0 15px 25px;
    width: calc(100% - 40px);
    transition: all ease-in-out 0.2s;
`;

const Section = styled(Flex.Item)`
    display: ${(props) => (props.hide ? "none" : "")};
`;

class Scroller extends React.Component {
    constructor(props) {
        super(props);
        this.scrollerObserver = new IntersectionObserver(
            this.intersectionHandler,
            {
                threshold: props.threshold || 0.5,
                rootMargin: props.rootMargin,
            }
        );

        this.state = {
            navbar: props.navSectionHeaders.reduce((acc, header) => {
                return {
                    ...acc,
                    [header.id]: false,
                };
            }, {}),
            shouldHideScroller: this.shouldHideScroller(),
        };

        window.addEventListener("resize", this.onWindowSizeChange);
    }

    shouldHideScroller = () => {
        if (window && window.screen && window.screen.width) {
            return window.screen.width < 940 ? true : false;
        }
        return false;
    };

    onWindowSizeChange = () => {
        let shouldHideScroller = this.shouldHideScroller();
        if (shouldHideScroller !== this.state.shouldHideScroller) {
            this.setState({ shouldHideScroller });
        }
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowSizeChange);
        this.scrollerObserver.disconnect();
    }

    intersectionHandler = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const id = entry.target && entry.target.id;
                const { navSectionHeaders } = this.props;
                const navbar = navSectionHeaders.reduce((acc, header) => {
                    return {
                        ...acc,
                        [header.id]: header.id === id,
                    };
                }, {});
                if (navbar[id]) {
                    navbar[id] = true;
                }
                this.setState({ navbar });
            }
        });
    };

    render() {
        const { navSectionHeaders } = this.props;
        const { navbar, shouldHideScroller } = this.state;

        return (
            <Flex.Container>
                <Section
                    width="20%"
                    style={{ position: "relative" }}
                    hide={!!shouldHideScroller}
                >
                    <Container>
                        <ScrollerNavContainer>
                            {navSectionHeaders.map((header, i) => {
                                const isActive = navbar[header.id];

                                return (
                                    <Flex.Container key={i}>
                                        <BallContainer isActive={isActive}>
                                            <OutterBall isActive={isActive} />
                                            <Ball isActive={isActive} />
                                        </BallContainer>
                                        <NavText
                                            size="s"
                                            color={
                                                isActive
                                                    ? "primary"
                                                    : "lightGrey"
                                            }
                                            weight={isActive ? 600 : 200}
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                smoothScrollTo({
                                                    id: header.id,
                                                })
                                            }
                                        >
                                            {header.name}
                                        </NavText>
                                    </Flex.Container>
                                );
                            })}
                        </ScrollerNavContainer>
                    </Container>
                </Section>
                <Flex.Item width="75%" tabletWidth="100%" mobileWidth="100%">
                    {this.props.children(this.scrollerObserver)}
                </Flex.Item>
            </Flex.Container>
        );
    }
}

export default Scroller;
